import { Alert, Snackbar } from '../imports';

import { useDataSendStatus } from '../services/useDataSendStatus';

export default function DataSendStatusSnackbar() {
  const { dataSendStatus, setDataSendStatus } = useDataSendStatus();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={dataSendStatus.open}
      autoHideDuration={4000}
      onClose={() => setDataSendStatus({ ...dataSendStatus, open: false })}>
      <Alert severity={dataSendStatus.success ? 'success' : 'error'} sx={{ width: '100%' }}>
        {dataSendStatus.message}
      </Alert>
    </Snackbar>
  );
}
