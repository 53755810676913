import { useCallback, useEffect, useRef, useState } from 'react';

import { TextField, TextFieldProps } from '../imports';

import email_validate from '../utils/validators/email';

type EmailTextFieldProp = {
  value?: string;
  onChange?: (value: string) => void;
  bulkChange?: boolean;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export default function EmailTextField({ onChange, bulkChange = true, ...props }: EmailTextFieldProp) {
  const isFirstRender = useRef(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [value, setValue] = useState<string>(props.value ?? '');

  const validate = useCallback((value: string): void => {
    setError(false);
    setErrorText('');

    if (!props.disabled) {
      if (props.required && value.length === 0) {
        setError(true);
        setErrorText('Email is required');
      } else {
        const result: string = email_validate(value);
        if (result) {
          setError(true);
          setErrorText(result);
        }
      }
    }
  }, [props.disabled, props.required]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    validate(props.value ?? '');
    setValue(props.disabled ? '' : props.value ?? '');
  }, [props.disabled, props.value, validate]);

  return (
    <TextField
      {...props}
      {...(error && { error: true, helperText: errorText })}
      value={value}
      onBlur={(event) => {
        validate(event.target.value);
        bulkChange && onChange?.(event.target.value);
      }}
      onChange={(event) => {
        error && validate(event.target.value);
        setValue(event.target.value);
        !bulkChange && onChange?.(event.target.value);
      }}
    />
  );
}
