import {
  Button,
  ClearIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '../imports';

interface DialogDeleteProps {
  open: boolean;
  itemName: string;
  entityName: string;
  dialogCallback: (result: boolean) => void;
}

export default function DialogDelete(props: DialogDeleteProps) {
  function onAgree() {
    props.dialogCallback(true);
  }

  function onClose() {
    props.dialogCallback(false);
  }

  return (
    <Dialog open={props.open} onClose={onClose} fullWidth>
      <DialogTitle>
        Delete {props.entityName}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Do you really want to delete {props.entityName} "{props.itemName}" ?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ mb: 1, mr: 1 }}>
        <Button color="error" variant="contained" onClick={onAgree}>
          Delete
        </Button>
        <Button variant="elevated" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
