import axios from 'axios';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert, ArrowBackIcon, Box, Button, Container, FormControl, Typography } from '../../../imports';

import LoadingButton from '../../../components/LoadingButton';
import PasswordTextField from '../../../components/PasswordTextField';

import { ApiUsersResetPasswordReset } from '../../../constants/endpoints';
import { UrlLogin } from '../../../constants/urls';

import useAbortSignal from '../../../services/useAbortSignal';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import password_validate from '../../../utils/validators/password';

export default function ResetPasswordPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSent, setDataSent] = useState(false);

  const navigate = useNavigate();
  const { checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();
  const urlParams = useParams();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password_validate(password) || password !== passwordConfirmation) {
      return;
    }

    setShowError(false);
    setLoading(true);
    axios
      .post(
        ApiUsersResetPasswordReset,
        {
          token: urlParams.token,
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
        },
        { signal: abortSignal }
      )
      .then(() => {
        setDataSent(true);
      })
      .catch((e) => {
        checkResponseError(e);
        setShowError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setEmail(urlParams.email ? window.atob(urlParams.email) : '');
    document.title = 'Travel Database - Reset password';
  }, [urlParams.email]);

  return (
    <>
      <Button variant="contained" startIcon={<ArrowBackIcon />} sx={{ margin: 3 }} onClick={() => navigate(UrlLogin)}>
        Back
      </Button>
      <Container component="main" maxWidth="xs">
        <Box component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography variant="h5" sx={{ marginBottom: 3 }}>
              Reset password
            </Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              You are resetting password for email: {email}
            </Alert>

            {showError && (
              <Alert severity="error" sx={{ mb: 3 }}>
                Error resetting password
              </Alert>
            )}
            {dataSent && <Typography sx={{ whiteSpace: 'nowrap' }}>Password reset, you can login</Typography>}
            {!dataSent && (
              <>
                <FormControl fullWidth margin="dense">
                  <PasswordTextField
                    required
                    fullWidth
                    variant="outlined"
                    name="password"
                    label="New password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(val) => setPassword(val)}
                  />
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <PasswordTextField
                    required
                    fullWidth
                    variant="outlined"
                    name="passwordConfirmation"
                    label="New password (repeat)"
                    type="password"
                    id="passwordConfirmation"
                    value={passwordConfirmation}
                    confirm={true}
                    confirmValue={password}
                    onChange={(val) => setPasswordConfirmation(val)}
                  />
                </FormControl>
              </>
            )}
          </Box>
          <Box sx={{ mt: 3 }}>
            {dataSent ? (
              <Button variant="contained" onClick={() => navigate(UrlLogin)}>
                Back to Login
              </Button>
            ) : (
              <LoadingButton type="submit" text="Send" sendingData={loading} />
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}
