import { useNavigate } from 'react-router-dom';

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '../../imports';

import Page from '../../models/PageConfig/Page';

import { useAuth } from '../../services/useAuth';
import usePageConfig from '../../services/usePageConfig';
import useSecondaryMenu from '../../services/useSecondaryMenu';

import { check_user_access } from '../../utils/utils';

function isSelected(page: Page, currentPage: Page): boolean {
  const children = page.children;

  return !!(
    page === currentPage ||
    (children && Object.keys(children).find((key) => isSelected(children[key], currentPage)))
  );
}

export default function LeftMenuItem({ page }: { page: Page }) {
  const navigate = useNavigate();
  const { currentPage } = usePageConfig();
  const { user } = useAuth();
  const secondaryMenu = useSecondaryMenu();

  function onClick() {
    secondaryMenu.setSelectedItems([]);

    if (currentPage !== page) {
      navigate(page.path);
    }
  }

  return check_user_access(page.role, user.role) ? (
    <ListItem disablePadding>
      <ListItemButton selected={isSelected(page, currentPage)} onClick={onClick}>
        <ListItemIcon>{page.icon}</ListItemIcon>
        <ListItemText>{page.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  ) : (
    <></>
  );
}
