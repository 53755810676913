import Code from '../models/Code';
import { format } from 'date-fns';
import { DateFormatApiFull } from './utils';

export const CodeInit: Code = {
  id: '0',
  identifier: '',
  name: '',
  key: '',
  type: 'Seat type',
  carrier: '',
  carrierName: '',
  class: '',
  tags: [],
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  status: 'To verify',
  notes: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
  translations: {},
};
