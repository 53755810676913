import { useEffect, useMemo, useState } from 'react';

import { Autocomplete, TextField } from '../../imports';

import { createFilterOptions } from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { SxProps, Theme } from '@mui/material';

import AutocompleteOption from '../../models/AutocompleteOption';

import useAxios from '../../services/useAxios';
import { useDataSendStatus } from '../../services/useDataSendStatus';

interface AutocompleteLoadingProps {
  urlPrefix: string;
  label: string;
  parentId?: string;
  value?: string;
  valueName?: string;
  loadAll?: boolean;
  disabled?: boolean;
  onChangeValue?: (value: AutocompleteOption | null) => void;
  inputSx?: SxProps<Theme> | undefined;
  error?: boolean;
  helperText?: string;
  onInputBlur?: any;
  minCharacters?: number;
}

export default function AutocompleteLoading(props: AutocompleteLoadingProps) {
  const [value, setValue] = useState<AutocompleteOption | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadedData, setLoadedData] = useState<AutocompleteOption[]>([]);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const axiosHelper = useAxios();
  const { checkResponseError } = useDataSendStatus();
  const minCharacters = props.minCharacters ?? 3;

  const filterOptions = createFilterOptions({
    stringify: (option: AutocompleteOption) => option.name,
  });

  const fetch = useMemo(
    () =>
      debounce((request: string, callback: (results?: readonly AutocompleteOption[]) => void) => {
        let url =
          props.urlPrefix +
          '?search=' +
          encodeURIComponent(request) +
          (props.parentId ? '&parentId=' + encodeURIComponent(props.parentId) : '');

        if (props.loadAll) {
          url = props.urlPrefix;
        }

        setLoading(true);
        axiosHelper
          .get(url)
          .then((response) => {
            callback(
              response.data.data?.map((v: any) => {
                return { id: v.id as string, name: v.name as string };
              })
            );
          })
          .catch(checkResponseError)
          .finally(() => setLoading(false));
      }, 500),
    [checkResponseError, props.parentId, props.urlPrefix, axiosHelper, props.loadAll]
  );

  useEffect(() => {
    let active = true;

    if (!props.loadAll && (inputValue === '' || inputValue.length < minCharacters)) {
      setOptions([]);
      return undefined;
    }

    if (!loadedData.length) {
      fetch(inputValue, (results?: readonly AutocompleteOption[]) => {
        if (active) {
          let newOptions: AutocompleteOption[] = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);

          if (props.loadAll) {
            setLoadedData(newOptions);
          }
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, props.loadAll, loadedData.length, minCharacters]);

  useEffect(() => {
    if (props.value) {
      const val: AutocompleteOption = { id: props.value, name: props.valueName ?? '' };
      if (val.id || val.name) {
        setValue(val);
      }
    } else {
      setValue(null);
    }
  }, [props.value, props.valueName]);

  return (
    <Autocomplete
      disabled={props.disabled ?? false}
      getOptionLabel={(option) => option.name}
      filterOptions={props.loadAll ? filterOptions : (x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      noOptionsText="No matches"
      isOptionEqualToValue={(option, value) => String(option.id) === String(value.id)}
      onChange={(_event: any, newValue: AutocompleteOption | null) => {
        if (!props.loadAll) {
          setOptions(newValue ? [newValue, ...options] : options);
        }

        setValue(newValue);

        if (props.onChangeValue) {
          props.onChangeValue(newValue);
        }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          margin="dense"
          sx={props.inputSx}
          {...params}
          label={props.label}
          placeholder={'Type ' + minCharacters + ' chars to search'}
          fullWidth
          onBlur={() => (props.onInputBlur ? props.onInputBlur() : undefined)}
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}
