import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldType from '../../../components/GridFieldType';
import GridFieldUpdated from '../../../components/GridFieldUpdated';
import GridFieldActions from '../../../components/GridFieldActions';

import { ApiEquipments } from '../../../constants/endpoints';
import { UrlEquipments } from '../../../constants/urls';

export default function EquipmentsList() {
  const columns: GridColDef[] = [
    GridFieldStatus(),
    GridFieldIdentifier(),
    { field: 'manufacturerName', headerName: 'Manufacturer' },
    GridFieldName(),
    GridFieldType(),
    { field: 'iata', headerName: 'IATA' },
    { field: 'icao', headerName: 'ICAO' },
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlEquipments }),
  ];

  return (
    <>
      <DataGridRestSort
        columns={columns}
        endpoint={ApiEquipments}
        navigation={UrlEquipments}
        rootNode="equipments"
        sortModel={{ field: 'manufacturerName', sort: 'asc' }}
      />
    </>
  );
}
