import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, OutlinedInput } from '../../../imports';

import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import NameField from '../../../components/NameField';
import TranslationsDrawer from '../../../components/TranslationsDrawer';

import Continent from '../../../models/Continent';
import { Translations } from '../../../models/Translations';

import { ApiContinents } from '../../../constants/endpoints';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { ContinentInit } from '../../../constants/ContinentInit';
import { LocaleEn } from '../../../constants/utils';
import { UrlContinents } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

export default function ContinentsEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Continent>(ContinentInit);
  const [loadedFormData, setLoadedFormData] = useState<Continent>(ContinentInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { continentId } = useParams();
  const axiosHelper = useAxios();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const updateLoadedFormData = useCallback(
    (data: Continent) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): Continent {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '0',
      code: (response.code as string) ?? '',
      name: (response.name as string) ?? '',
      created: response.created as string,
      updated: response.updated as string,
      createdBy: response.created_by as string,
      updatedBy: response.updated_by as string,
      translations: response?.translations || {},
    };
  }

  const handleTranslationsChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      name: data[LocaleEn] || '',
      translations: {
        ...prevState.translations,
        name: data,
      },
    }));
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiContinents + '/' + continentId, formData);
    } else {
      request = axiosHelper.post(ApiContinents, formData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.continents)[0]));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Continent created' });
          navigate(UrlContinents);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (continentId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiContinents + '/' + continentId)
        .then((response) => {
          updateLoadedFormData(
            getDataFromResponse((Object.values(response.data.data.continents)[0] as Continent) ?? ContinentInit)
          );
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add continent');
    }
  }, [continentId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (continentId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [continentId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiContinents + '/' + continentId)
        .then(() => navigate(UrlContinents))
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="code">Code</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="code"
                          id="code"
                          label="Code"
                          type="text"
                          value={formData.code}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <NameField
                    value={(formData.translations.name && formData.translations.name[LocaleEn]) || ''}
                    onClick={handleToggleDrawer}
                  />

                  <TranslationsDrawer
                    name="name"
                    label="Name"
                    open={drawer}
                    globalDesignation={true}
                    translations={formData.translations.name}
                    onChange={handleTranslationsChange}
                    onClose={setDrawer}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="continent"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
