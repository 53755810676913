import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Alert, Box, Button, Container, Typography } from '../../imports';

import LoadingPage from '../../components/LoadingPage';

import { ApiUsersVerifyEmail } from '../../constants/endpoints';
import { ResetPasswordInfoInit } from '../../constants/ResetPasswordInfoInit';
import { UrlResetPassword } from '../../constants/urls';

import ResetPasswordInfo from '../../models/user/ResetPasswordInfo';

import useAbortSignal from '../../services/useAbortSignal';

export default function VerifyEmail() {
  const [reserPasswordInfo, setReserPasswordInfo] = useState<ResetPasswordInfo>(ResetPasswordInfoInit);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const abortSignal = useAbortSignal();

  useEffect(() => {
    // Parameter order is critical
    const info = {
      expires: searchParams.get('expires') ?? '',
      hash: searchParams.get('hash') ?? '',
      id: searchParams.get('id') ?? '',
      signature: searchParams.get('signature') ?? '',
    };

    const sendData = Object.entries(info)
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&');

    setShowError(false);
    setLoading(true);
    axios
      .get(ApiUsersVerifyEmail + '?' + sendData, {
        signal: abortSignal,
      })
      .then((response) => {
        setReserPasswordInfo((response.data?.data as ResetPasswordInfo) ?? ResetPasswordInfoInit);
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => setLoading(false));
  }, [abortSignal, searchParams]);

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ marginTop: '90px' }}>
        {loading && <LoadingPage />}
        {!loading && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 3 }}>
                Account verification
              </Typography>
              {!showError && (
                <Alert severity="info" sx={{ mb: 3 }}>
                  Your Travel DB account has been verified successfully. You can click on the button below to reset your password.
                </Alert>
              )}

              {showError && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  Error validating Travel DB account
                </Alert>
              )}
            </Box>

            {!showError && (
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate([UrlResetPassword, reserPasswordInfo.token, reserPasswordInfo.emailHash].join('/'))
                  }
                >
                  Go to reset password
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </>
  );
}
