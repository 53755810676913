import { useCallback, useEffect, useRef, useState } from 'react';

import { TextField, TextFieldProps } from '../imports';

import password_validate from '../utils/validators/password';

type PasswordTextFieldProp = {
  value?: string;
  onChange?: (value: string) => void;
  bulkChange?: boolean;
  confirm?: boolean;
  confirmValue?: string;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export default function PasswordTextField({
  onChange,
  bulkChange = true,
  confirm = false,
  confirmValue,
  ...props
}: PasswordTextFieldProp) {
  const isFirstRender = useRef(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [value, setValue] = useState<string>(props.value ?? '');

  const validate = useCallback(
    (value: string): void => {
      setError(false);
      setErrorText('');

      if (!props.disabled) {
        if (confirm) {
          if (value !== confirmValue) {
            setError(true);
            setErrorText("Password don't match");
          }
        } else {
          if (props.required && value.length === 0) {
            setError(true);
            setErrorText('Password is required');
          } else {
            const result: string = password_validate(value);
            if (result) {
              setError(true);
              setErrorText(result);
            }
          }
        }
      }
    },
    [props.disabled, props.required, confirmValue, confirm]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    validate(props.value ?? '');
    setValue(props.disabled ? '' : props.value ?? '');
  }, [props.disabled, props.value, confirmValue, validate]);

  return (
    <TextField
      {...props}
      {...(error && { error: true, helperText: errorText })}
      value={value}
      onChange={(event) => {
        error && validate(event.target.value);
        setValue(event.target.value);
        !bulkChange && onChange?.(event.target.value);
      }}
      onBlur={(event) => {
        validate(event.target.value);
        bulkChange && onChange?.(event.target.value);
      }}
    />
  );
}
