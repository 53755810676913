import { format } from 'date-fns';

import Carrier from '../models/Carrier';

import { DateFormatApiFull } from './utils';

export const CarrierInit: Carrier = {
  id: '0',
  identifier: '',
  iata: '',
  icao: '',
  bsp: '',
  ccon: '',
  company: '',
  name: '',
  nameWithoutDiacritics: '',
  type: '',
  founded: '',
  ceased: '',
  ceasedReason: '',
  numberOfStations: '',
  headquarterCountry: '',
  headquarterCountryName: '',
  headquarterCity: '',
  headquarterCityName: '',
  website: '',
  generalConditionsOfCarriage: {},
  status: 'To verify',
  notes: '',
  logo: '',
  mime: '',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
};
