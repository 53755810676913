export const EquipmentTypes: string[] = [
  'aircraft',
  'bus',
  'car',
  'helicopter',
  'hovercraft',
  'train',
  'truck',
  'watercraft',
];
