import { useState } from 'react';
import { format, subWeeks, subYears } from 'date-fns';

import {
  AdapterDateFns,
  Box,
  DatePicker,
  FormControlLabel,
  LocalizationProvider,
  Radio,
  RadioGroup,
} from '../../imports';

import FilterData from '../../models/Filter/FilterData';

const dateFormat = 'yyyy-MM-dd';

function formatDate(date: Date) {
  return format(date, dateFormat);
}

export default function Update({ data }: { data: FilterData }) {
  const now = new Date();

  const [operator, setOperator] = useState('>=');
  const [value, setValue] = useState(formatDate(subWeeks(now, 1)));
  const [datepickerEnabled, setDatepickerEnabled] = useState(false);

  data.operator = operator;

  if (operator === '>=') {
    data.operatorLabel = 'is newer than';
  } else if (operator === '<=') {
    data.operatorLabel = 'is older than';
  }

  data.value = value;
  data.valueLabel = value;

  function changeType(type: string) {
    if (type === 'itp7d') {
      setOperator('>=');
      setValue(formatDate(subWeeks(now, 1)));
      setDatepickerEnabled(false);
    } else if (type === 'int') {
      setOperator('>=');
      setValue(formatDate(now));
      setDatepickerEnabled(true);
    } else if (type === 'iot') {
      setOperator('<=');
      setValue(formatDate(now));
      setDatepickerEnabled(true);
    } else if (type === 'mt1ya') {
      setOperator('<=');
      setValue(formatDate(subYears(now, 1)));
      setDatepickerEnabled(false);
    }
  }

  return (
    <RadioGroup name="type" defaultValue="itp7d" onChange={(event) => changeType(event.target.value)}>
      <FormControlLabel value="itp7d" control={<Radio />} label="in the previous 7 days" />
      <FormControlLabel value="int" control={<Radio />} label="is newer than" />

      <Box my={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker format={dateFormat} disabled={!datepickerEnabled} value={new Date(value)} label="Date" />
        </LocalizationProvider>
      </Box>

      <FormControlLabel value="iot" control={<Radio />} label="is order than" />
      <FormControlLabel value="mt1ya" control={<Radio />} label="more than 1 year ago" />
    </RadioGroup>
  );
}
