import PageConfigContextData from '../../models/PageConfig/PageConfigContextData';

import { PageInit } from './PageInit';

export const PageConfigContextDataInit: PageConfigContextData = {
  currentPage: PageInit,
  pagePath: [],
  title: '',
  setTitle() {},
};
