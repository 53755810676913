import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  PowerSettingsNewIcon,
  Typography,
  ListSubheader,
  Divider,
} from '../../imports';

import LeftMenuItem from './LeftMenuItem';

import { pageConfig } from '../../constants/PageConfig/PageConfig';

import { useAuth } from '../../services/useAuth';

import { check_user_access } from '../../utils/utils';

export default function LeftMenu() {
  const { user, logout } = useAuth();

  return (
    <Drawer variant="permanent" anchor="left" sx={{ '.MuiDrawer-paper': { backgroundColor: '#0c1f31' } }}>
      <Box minHeight="100%">
        <Box position="relative" minHeight="100%" pt={2.5} pb={10}>
          <List sx={{ mt: 0 }}>
            <ListItem>
              {/*<img src="/app.jpg" alt="Database" style={{ width: 56, height: 56, borderRadius: 16 }} />*/}

              <Typography component="span" fontSize="18px" sx={{ /*ml: 2,*/ fontWeight: 'bold' }}>
                Aviation
              </Typography>
            </ListItem>

            <ListSubheader disableSticky>Home</ListSubheader>
            <LeftMenuItem page={pageConfig.dashboard} />

            <ListSubheader disableSticky>Master data</ListSubheader>
            <LeftMenuItem page={pageConfig.carriers} />
            <LeftMenuItem page={pageConfig.locations} />
            <LeftMenuItem page={pageConfig.equipments} />
            <LeftMenuItem page={pageConfig.codes} />

            <ListSubheader disableSticky>Geo</ListSubheader>
            <LeftMenuItem page={pageConfig.cities} />
            <LeftMenuItem page={pageConfig.countries} />
            <LeftMenuItem page={pageConfig.continents} />

            {check_user_access(pageConfig.users.role, user.role) && (
              <>
                <Divider />
                <ListSubheader disableSticky>Administration</ListSubheader>
                <LeftMenuItem page={pageConfig.users} />
              </>
            )}
          </List>

          <List sx={{ position: 'absolute', bottom: 0 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}
