import { FormControl, InputLabel, OutlinedInput } from '../imports';

interface CodeCconFieldProps {
  value: string;
}

export default function CodeCconField({ value }: CodeCconFieldProps) {
  return (
    <FormControl margin="dense" fullWidth>
      <InputLabel htmlFor="ccon">CCON code</InputLabel>
      <OutlinedInput
        fullWidth
        readOnly
        sx={{ backgroundColor: '#F1F1F1' }}
        name="ccon"
        id="ccon"
        label="CCON code"
        type="text"
        defaultValue={value}
      />
    </FormControl>
  );
}
