import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiCodes } from '../../../constants/endpoints';
import { UrlCodes } from '../../../constants/urls';

export default function CodesList() {
  const columns: GridColDef[] = [
    GridFieldStatus({}),
    GridFieldIdentifier(),
    {
      field: 'type',
      headerName: 'Type',
      flex: 7,
    },
    {
      field: 'key',
      headerName: 'Key',
      flex: 12,
    },
    GridFieldName(),
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 12,
      renderCell: (params: GridRenderCellParams) => (params.value ? params.value.join(', ') : ''),
    },
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlCodes }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiCodes}
      navigation={UrlCodes}
      rootNode="codes"
      sortModel={{ field: 'key', sort: 'asc' }}
    />
  );
}
