export const UrlDashboard = '/';
export const UrlCarriers = '/carriers';
export const UrlLocations = '/locations';
export const UrlCodes = '/codes';
export const UrlContinents = '/continents';
export const UrlCountries = '/countries';
export const UrlCities = '/cities';
export const UrlEquipments = '/equipments';
export const UrlLogin = '/login';
export const UrlProfile = '/profile';
export const UrlResetPassword = '/reset-password';
export const UrlUsers = '/users';
export const UrlVerifyEmail = '/verify-email';
