import { FormControl, InputLabel, OutlinedInput } from '../imports';

interface IdentifierFieldProps {
  value: string;
}

export default function IdentifierField({ value }: IdentifierFieldProps) {
  return (
    <FormControl margin='dense' fullWidth>
      <InputLabel htmlFor='identifier'>Identifier</InputLabel>
      <OutlinedInput
        fullWidth
        readOnly
        name='identifier'
        sx={{ backgroundColor: 'rgba(133, 188, 67, 0.3)' }}
        id='identifier'
        label='Identifier'
        type='text'
        defaultValue={value}
      />
    </FormControl>
  );
}
