import { useCallback, useEffect, useState } from 'react';

import { Autocomplete, TextField } from '../../imports';

import { debounce } from '@mui/material/utils';

import AutocompleteOption from '../../models/AutocompleteOption';

import useAxios from '../../services/useAxios';
import { useDataSendStatus } from '../../services/useDataSendStatus';

interface AutocompleteLoadingMultipleProps {
  label: string;
  urlPrefix: string;
  value: AutocompleteOption[];
  setValue: (values: AutocompleteOption[]) => void;
}

export default function AutocompleteLoadingMultiple(props: AutocompleteLoadingMultipleProps) {
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const axios = useAxios();
  const { checkResponseError } = useDataSendStatus();

  useEffect(() => {
    if (inputValue.length >= 3) {
      setLoading(true);
      setOptions([]);

      axios
        .get(props.urlPrefix + '?search=' + encodeURIComponent(inputValue))
        .then((response) => {
          setOptions(response.data.data.map((data: any) => ({ id: data.id, name: data.name })));
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }, [inputValue]);

  const handleInputValueChange = useCallback(debounce(setInputValue, 500), []);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      value={props.value}
      multiple={true}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      onChange={(_event, value) => props.setValue(value)}
      renderInput={(params) => <TextField margin="dense" {...params} label="Country" />}
      onInputChange={(_event, newInputValue) => handleInputValueChange(newInputValue)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
