import { Box, EditIcon, IconButton } from '../imports';
import { iconStyle } from './DataGridRestSort';
import { useNavigate } from 'react-router-dom';

interface GridIconEditProps {
  urlEdit: string;
}

export default function GridIconEdit(props: GridIconEditProps) {
  const navigate = useNavigate();

  return (
    <Box component="span" width={28}>
      <IconButton size="small" onClick={() => navigate(props.urlEdit)}>
        <EditIcon sx={iconStyle} />
      </IconButton>
    </Box>
  );
}
