import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Checkbox,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  SearchIcon,
} from '../../imports';

import SecondaryMenuItemSingle from './SecondaryMenuItemSingle';
import SecondaryMenuItemMultiple from './SecondaryMenuItemMultiple';

import { leftMenuWidth } from '../../constants/utils';

import useSecondaryMenu from '../../services/useSecondaryMenu';
import usePageConfig from '../../services/usePageConfig';

export default function SecondaryMenu() {
  const [filterValue, setFilterValue] = useState('');

  const location = useLocation();
  const { setTitle } = usePageConfig();
  const secondaryMenu = useSecondaryMenu();

  const open = secondaryMenu.type !== 'none';

  useEffect(() => {
    secondaryMenu.setType('none');
    secondaryMenu.setItems([]);
    secondaryMenu.setSelectedItems([]);
    setTitle('');
  }, [location.pathname]);

  function handleSelectAll() {
    if (secondaryMenu.selectedItems.length === secondaryMenu.items.length) {
      secondaryMenu.setSelectedItems([]);
    } else {
      secondaryMenu.setSelectedItems(secondaryMenu.items);
    }
  }

  if (!open && filterValue) {
    setFilterValue('');
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{ '& .MuiDrawer-paper': { left: leftMenuWidth, backgroundColor: '#07233e' } }}>
      <List sx={{ mt: 0 }}>
        <ListSubheader sx={{ mt: 0 }} disableSticky>
          Filter
        </ListSubheader>

        <ListItem disablePadding>
          <OutlinedInput
            fullWidth
            size="small"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            endAdornment={<SearchIcon />}
          />
        </ListItem>

        <Divider sx={{ my: 2 }} />

        {secondaryMenu.type === 'multiple' && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={handleSelectAll}>
                <ListItemIcon>
                  <Checkbox checked={secondaryMenu.selectedItems.length === secondaryMenu.items.length} />
                </ListItemIcon>
                <ListItemText>Select All</ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider />
          </>
        )}

        {secondaryMenu.items.map(
          (item) =>
            (!filterValue || item.name.toLowerCase().includes(filterValue.toLowerCase())) && (
              <Fragment key={item.value}>
                {secondaryMenu.type === 'single' && <SecondaryMenuItemSingle item={item} />}
                {secondaryMenu.type === 'multiple' && <SecondaryMenuItemMultiple item={item} />}
              </Fragment>
            )
        )}
      </List>
    </Drawer>
  );
}
