import { useState } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '../../imports';

import FilterData from '../../models/Filter/FilterData';
import AutocompleteOption from '../../models/AutocompleteOption';

import { ApiCitiesSearch } from '../../constants/endpoints';

import AutocompleteLoadingMultiple from '../Autocomplete/AutocompleteLoadingMultiple';

export default function City({ data }: { data: FilterData }) {
  const [operator, setOperator] = useState('IN');
  const [values, setValues] = useState<AutocompleteOption[]>([]);

  data.operator = operator;

  if (operator === 'IN') {
    data.operatorLabel = 'is';
  } else {
    data.operatorLabel = 'is not';
  }

  data.value = values.map((value) => value.id);
  data.valueLabel = values.map((value) => value.name);

  return (
    <RadioGroup name="operator" value={operator} onChange={(event) => setOperator(event.target.value)}>
      <FormControlLabel value="IN" control={<Radio />} label="is" />

      <AutocompleteLoadingMultiple
        label="City"
        urlPrefix={ApiCitiesSearch}
        value={values}
        setValue={setValues}
      />

      <FormControlLabel value="NOT IN" control={<Radio />} label="is not" />
    </RadioGroup>
  );
}
