import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldCountryName from '../../../components/GridFieldCountryName';
import GridFieldStatus from '../../../components/GridFieldStatus';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiCities } from '../../../constants/endpoints';
import { UrlCities } from '../../../constants/urls';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';
import GridFieldName from '../../../components/GridFieldName';

export default function CitiesList() {
  const columns: GridColDef[] = [
    GridFieldStatus(),
    GridFieldIdentifier(),
    GridFieldName(),
    { field: 'unlo', headerName: 'UN/LOCODE', flex: 9 },
    { field: 'iata', headerName: 'IATA code', flex: 7 },
    GridFieldCountryName(),
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlCities }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiCities}
      navigation={UrlCities}
      rootNode="cities"
      sortModel={{ field: 'name', sort: 'asc' }}
    />
  );
}
