import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { DataSendStatusContextDataInit } from '../constants/DataSendStatus/DataSendStatusContextDataInit';
import { DataSendStatusInit } from '../constants/DataSendStatus/DataSendStatusInit';
import { useAuth } from './useAuth';
import { CanceledError } from 'axios';

const DataSendStatusContext = createContext(DataSendStatusContextDataInit);

export function DataSendStatusProvider(props: { children: ReactNode }) {
  const [dataSendStatus, setDataSendStatus] = useState(DataSendStatusInit);

  const { logout } = useAuth();

  const checkResponseError = useCallback(
    (error: any) => {
      if (error instanceof CanceledError) {
        return;
      } else if (error.toJSON().status === 401) {
        logout();
      } else {
        let message = 'Error exchanging data! ';
        const data = error.response?.data;

        if (data?.message) {
          message += data?.message;
        } else if (data?.meta?.message) {
          message += data?.meta?.message;
        }

        setDataSendStatus({ open: true, success: false, message: message });
      }
    },
    [logout]
  );

  return (
    <DataSendStatusContext.Provider value={{ dataSendStatus, setDataSendStatus, checkResponseError }}>
      {props.children}
    </DataSendStatusContext.Provider>
  );
}

export function useDataSendStatus() {
  return useContext(DataSendStatusContext);
}
