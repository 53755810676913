import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Login from '../pages/Login';
import ResetPasswordEmail from '../pages/ResetPassword/ResetPasswordEmail';
import ResetPasswordPassword from '../pages/ResetPassword/ResetPasswordPassword';
import VerifyEmail from '../pages/VerifyEmail';

import { UrlDashboard, UrlLogin, UrlResetPassword, UrlVerifyEmail } from '../constants/urls';

export default function RouterNotAuthorized() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path={UrlDashboard} element={<Login />} />
        <Route path={UrlLogin} element={<Login />} />
        <Route path={UrlResetPassword} element={<ResetPasswordEmail />} />
        <Route path={UrlResetPassword + '/:token/:email'} element={<ResetPasswordPassword />} />
        <Route path={UrlVerifyEmail} element={<VerifyEmail />} />
        <Route path="*" element={<Login redirectTo={window.location.pathname} />} />
      </Route>
    )
  );
}
