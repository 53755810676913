import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import RootLayout from '../layouts/Root';
import DefaultLayout from '../layouts/Default';

import PageConfig from '../models/PageConfig/PageConfig';

import { pageConfig } from '../constants/PageConfig/PageConfig';

import { useAuth } from '../services/useAuth';

import { check_user_access } from '../utils/utils';

export default function Router() {
  const { user } = useAuth();

  function getRoutesFromPages(pages: PageConfig, indexPrefix: string = '', pathPrefix: string = '') {
    return Object.keys(pages).map((key) => {
      const page = pages[key];

      return (
        check_user_access(page.role, user.role) && (
          <Route key={indexPrefix + key}>
            <Route path={pathPrefix + page.path} element={page.element} />
            {page.children && getRoutesFromPages(page.children, indexPrefix + key + '-', pathPrefix + page.path + '/')}
          </Route>
        )
      );
    });
  }

  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootLayout />}>
        <Route element={<DefaultLayout />}>{getRoutesFromPages(pageConfig)}</Route>
      </Route>
    )
  );
}
