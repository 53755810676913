import { useEffect, useState } from 'react';

import { Card, CardContent, CardHeader, Container, Grid } from '../../imports';

import LoadingPage from '../../components/LoadingPage';

import DashboardData from '../../models/Dashboard/DashboardData';

import { DashboardDataInit } from '../../constants/DashboardDataInit';
import { ApiDashboard } from '../../constants/endpoints';

import useAxios from '../../services/useAxios';
import { useDataSendStatus } from '../../services/useDataSendStatus';
import Table from './Table';

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DashboardData>(DashboardDataInit);

  const axiosHelper = useAxios();
  const { checkResponseError } = useDataSendStatus();

  useEffect(() => {
    axiosHelper
      .get(ApiDashboard)
      .then((response) => setData(response.data))
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [checkResponseError, axiosHelper]);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Container maxWidth="lg">
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item md={1} xs={2}>
              <Card variant="island">
                <CardHeader title={'Latest ' + data.updates.length + ' updates'} />

                <CardContent>
                  <Table data={data.updates} user="Editor" />
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={1} xs={2}>
              <Card variant="island">
                <CardHeader title={'Latest ' + data.reviews.length + ' reviews'} />

                <CardContent>
                  <Table data={data.reviews} user="Reviewer" />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
