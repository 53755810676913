import { ChatBubbleIcon, LanguageOutlinedIcon, MessageOutlinedIcon, SvgIcon } from '../imports';

export default function TranslationsIcon() {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: 'rgb(77, 77, 77)' }}>
      <LanguageOutlinedIcon />
      <ChatBubbleIcon viewBox="0 -23 45 45" sx={{ fill: 'white' }} />
      <MessageOutlinedIcon viewBox="0 -23 45 45" />
    </SvgIcon>
  );
}
