import { format } from 'date-fns';

import Location from '../models/Location';

import { DateFormatApiFull } from './utils';

export const LocationInit: Location = {
  id: '0',
  identifier: '',
  name: '',
  uic: '',
  iata: '',
  icao: '',
  ccon: '',
  official: '',
  eponym: '',
  website: '',
  indoorMap: '',
  directions: '',
  onsiteServices: '',
  opened: '',
  closed: '',
  main: false,
  type: '',
  aliases: [],
  country: '',
  countryName: '',
  countryCode: '',
  city: '',
  cityName: '',
  timezone: '',
  timezoneName: '',
  street: '',
  streetNumber: '',
  postcode: '',
  addressAdditional: '',
  latitude: '',
  longitude: '',
  altitude: '',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  pointsOfSale: [],
  status: 'To verify',
  notes: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
  translations: {},
};
