import { Box, Container, LinearProgress, Typography } from '../imports';

export default function LoadingPage() {
  return (
    <Box maxWidth="fixed">
      <Container maxWidth="xs">
        <Typography>Please wait while we retrieve data</Typography>
      </Container>
      <LinearProgress />
    </Box>
  );
}
