import { Button, DeleteIcon, Grid, Stack } from '../imports';
import DialogDelete from './DialogDelete';
import LoadingButton from './LoadingButton';

interface EditButtonsProps {
  action: string;
  itemName: string;
  entityName: string;
  sendingData: boolean;
  deleteDialogOpen: boolean;
  deleteDialogCallback: (result: boolean) => void;
  onReset: () => void;
  onDelete: () => void;
}

export default function EditButtons(props: EditButtonsProps) {
  return (
    <Grid container columns={2} rowSpacing={0} columnSpacing={3} paddingBottom={4}>
      <Grid item lg={1} xs={2}>
        {props.action === 'edit' && (
          <>
            <Button variant="elevated" onClick={props.onDelete} sx={{ minWidth: 0, float: 'right', mr: 0.5 }}>
              <DeleteIcon color="error" />
            </Button>

            <DialogDelete
              open={props.deleteDialogOpen}
              itemName={props.itemName}
              entityName={props.entityName}
              dialogCallback={props.deleteDialogCallback}
            />
          </>
        )}

        <Stack direction="row" ml={1}>
          <LoadingButton color="primary" text="Save" sendingData={props.sendingData} />

          <Button variant="elevated" onClick={props.onReset} sx={{ ml: 2 }}>
            Reset
          </Button>
        </Stack>
      </Grid>

      <Grid item lg={1} xs={2} />
    </Grid>
  );
}
