import { Box, Card, CardContent, CardHeader, Typography } from '../imports';

import { convert_date_full_from_api_full } from '../utils/utils';

interface HistoryCardProps {
  createdBy: string;
  created: string;

  updatedBy: string;
  updated: string;
}

export default function HistoryCard(props: HistoryCardProps) {
  return (
    <Card variant="history">
      <CardHeader title="History" />
      <CardContent>
        <Box>
          <Typography display="inline" sx={{ fontWeight: 'bold' }}>
            Created by{' '}
          </Typography>
          <Typography display="inline">{props.createdBy} • </Typography>
          <Typography display="inline">
            {props.created ? convert_date_full_from_api_full(props.created) : ''}
          </Typography>
        </Box>
        <Box>
          <Typography display="inline" sx={{ fontWeight: 'bold' }}>
            Last updated by{' '}
          </Typography>
          <Typography display="inline">{props.updatedBy} • </Typography>
          <Typography display="inline">
            {props.updated ? convert_date_full_from_api_full(props.updated) : ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
