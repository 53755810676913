import { createContext, ReactNode, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Page from '../models/PageConfig/Page';
import PageConfig from '../models/PageConfig/PageConfig';

import { PageConfigContextDataInit } from '../constants/PageConfig/PageConfigContextDataInit';
import { pageConfig } from '../constants/PageConfig/PageConfig';
import { UrlDashboard } from '../constants/urls';

const PageConfigContext = createContext(PageConfigContextDataInit);

function getPageProps(pageConfig: PageConfig, path: string[]): { currentPage: Page; pagePath: Page[] } {
  const pathItem = path.shift();

  const pageKey = Object.keys(pageConfig).find(
    (key) => pageConfig[key].path === pathItem || pageConfig[key].path.startsWith(':')
  );

  if (!pageKey) {
    return { currentPage: pageConfig.error, pagePath: [] };
  }

  let currentPage = pageConfig[pageKey];
  let pagePath: Page[] = [];

  if (path.length && currentPage.children) {
    const childPageProps = getPageProps(currentPage.children, path);

    if (childPageProps.currentPage === pageConfig.error) {
      return childPageProps;
    }

    pagePath = [currentPage, ...childPageProps.pagePath];
    currentPage = childPageProps.currentPage;
  }

  return { currentPage, pagePath };
}

export function PageConfigProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState('');

  const location = useLocation();

  const { currentPage, pagePath } =
    location.pathname !== UrlDashboard
      ? getPageProps(
          pageConfig,
          location.pathname.split('/').filter((x) => x)
        )
      : { currentPage: pageConfig.dashboard, pagePath: [] };

  return (
    <PageConfigContext.Provider
      value={{
        currentPage,
        pagePath,
        title,
        setTitle,
      }}>
      {children}
    </PageConfigContext.Provider>
  );
}

export default function usePageConfig() {
  return useContext(PageConfigContext);
}
