import { GridColDef } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldUpdated from '../../../components/GridFieldUpdated';
import GridFieldName from '../../../components/GridFieldName';
import GridFieldIdentifier from '../../../components/GridFieldIdentifier';

import { ApiContinents } from '../../../constants/endpoints';
import { UrlContinents } from '../../../constants/urls';

export default function ContinentsList() {
  const columns: GridColDef[] = [
    GridFieldIdentifier(),
    GridFieldName(),
    { field: 'code', headerName: 'Code', flex: 7 },
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlContinents }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiContinents}
      navigation={UrlContinents}
      rootNode="continents"
      sortModel={{ field: 'name', sort: 'asc' }}
    />
  );
}
