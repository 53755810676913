import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ucfirst } from '../utils/utils';

export default function GridFieldType(): GridColDef {
  return {
    field: 'type',
    headerName: 'Type',
    flex: 6,
    renderCell: (params: GridRenderCellParams) => {
      return params.value ? ucfirst(params.value) : '';
    },
  };
}
