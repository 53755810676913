export const DateFormatInput = 'dd.MM.yyyy';
export const DateFormatFull = 'dd.MM.yyyy, HH:mm:ss';
export const DateFormatInputExtended = 'dd.MM.yyyy HH:mm:ss';
export const DateFormatApi = 'yyyy-MM-dd';
export const DateFormatApiFull = 'yyyy-MM-dd HH:mm:ss';
export const DateFormatApiExtended = 'dd-MMM-yy HH:mm:ss';
export const leftMenuWidth = 300;

export const UserRoleAdmin = 'admin';
export const UserRoleOperator = 'operator';
export const UserRoleUser = 'user';
export const UserRoleApi = 'api';

export const PasswordResetLinkExpires = 1;

export const LocaleEn = 'en_GB';

export const PageSize = 25;
export const PageSizeOptions = [25, 50, 100];
