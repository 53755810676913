import ReactDOM from 'react-dom/client';

import { CssBaseline, ThemeProvider } from './imports';
import App from './App';
import { DefaultTheme } from './themes/Default';

import { AuthProvider } from './services/useAuth';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={DefaultTheme}>
    <CssBaseline />

    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>
);
