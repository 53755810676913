import { RouterProvider } from 'react-router-dom';

import Router from './components/Router';
import RouterNotAuthorized from './components/RouterNotAuthorized';
import { useAuth } from './services/useAuth';

export default function App() {
  const { user } = useAuth();

  return <RouterProvider router={user.token ? Router() : RouterNotAuthorized()} />;
}
