import { useState } from 'react';

export function useValidateField(formData: any, formErrorsInit: any) {
  const [formErrors, setFormErrors] = useState(formErrorsInit);

  const validateField = (field: string, validator: (value: string) => string, canBeEmpty: boolean = true): boolean => {
    const value = String(formData[field]);
    const error = validator(value);

    return setFieldError(field, value, canBeEmpty, error);
  };

  const setFieldError = (field: string, value: string, canBeEmpty: boolean, error: string): boolean => {
    let valid: boolean = true;

    if ((value || !canBeEmpty) && error !== '') {
      valid = false;
      setFormErrors((prevState: any) => ({ ...prevState, [field]: error }));
    } else {
      setFormErrors((prevState: any) => ({ ...prevState, [field]: '' }));
    }

    return valid;
  };

  return [validateField, setFieldError, formErrors, setFormErrors];
}
