import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import DataGridRestSort from '../../../components/DataGridRestSort';
import GridFieldActions from '../../../components/GridFieldActions';
import GridFieldUpdated from '../../../components/GridFieldUpdated';

import { ApiUsers } from '../../../constants/endpoints';
import { UrlUsers } from '../../../constants/urls';

import { ucfirst } from '../../../utils/utils';

export default function UsersList() {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 4 },
    { field: 'first_name', headerName: 'First name', flex: 10 },
    { field: 'last_name', headerName: 'Last name', flex: 10 },
    { field: 'email', headerName: 'Email', flex: 10 },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: (params: GridRenderCellParams) => {
        return ucfirst(params.value);
      },
      flex: 5,
    },
    GridFieldUpdated(),
    GridFieldActions({ baseUrlEdit: UrlUsers }),
  ];

  return (
    <DataGridRestSort
      columns={columns}
      endpoint={ApiUsers}
      navigation={UrlUsers}
      rootNode="users"
      sortModel={{ field: 'id', sort: 'desc' }}
      statusSwitcherColumn={true}
    />
  );
}
