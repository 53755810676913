import { format } from 'date-fns';

import Equipment from '../models/Equipment';

import { DateFormatApiFull } from './utils';

export const EquipmentInit: Equipment = {
  id: '0',
  identifier: '',
  name: '',
  iata: '',
  icao: '',
  type: '',
  manufacturer: '',
  manufacturerName: '',
  status: 'To verify',
  notes: '',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
  translations: {},
};
