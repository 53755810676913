import UserInfo from '../models/user/UserInfo';

export const UserInfoInit: UserInfo = {
  id: 0,
  token: '',
  name: '',
  email: '',
  role: '',
  supported_locales: {},
};
