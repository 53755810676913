import { Outlet } from 'react-router-dom';

import { useTheme, Box, ThemeProvider } from '../imports';
import { LeftMenuTheme } from '../themes/LeftMenu';
import LeftMenu from '../components/LeftMenu/LeftMenu';
import SecondaryMenu from '../components/SecondaryMenu/SecondaryMenu';
import DataSendStatusSnackbar from '../components/DataSendStatusSnackbar';
import Header from '../components/Header';

import { leftMenuWidth } from '../constants/utils';

import useSecondaryMenu from '../services/useSecondaryMenu';

export default function Default() {
  const theme = useTheme();
  const { type } = useSecondaryMenu();

  return (
    <>
      <ThemeProvider theme={LeftMenuTheme}>
        <LeftMenu />
        <SecondaryMenu />
      </ThemeProvider>

      <Box
        component="main"
        sx={{
          p: 4,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: (type !== 'none' ? leftMenuWidth * 2 : leftMenuWidth) + 'px',
        }}>
        <Header />
        <Outlet />
      </Box>

      <DataSendStatusSnackbar />
    </>
  );
}
