import { useState } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '../../imports';

import FilterData from '../../models/Filter/FilterData';

export default function Status({ data }: { data: FilterData }) {
  const [value, setValue] = useState('To verify');

  data.operator = '=';
  data.operatorLabel = 'is';
  data.value = value;
  data.valueLabel = value;

  return (
    <RadioGroup name="status" value={value} onChange={(event) => setValue(event.target.value)}>
      <FormControlLabel value="To verify" control={<Radio />} label="To verify" />
      <FormControlLabel value="Verified" control={<Radio />} label="Verified" />
    </RadioGroup>
  );
}
