import { ListItem, ListItemButton, ListItemText } from '../../imports';

import SecondaryMenuItem from '../../models/SecondaryMenu/SecondaryMenuItem';

import useSecondaryMenu from '../../services/useSecondaryMenu';

export default function SecondaryMenuItemSingle({ item }: { item: SecondaryMenuItem }) {
  const { selectedItems, setSelectedItems } = useSecondaryMenu();

  return (
    <ListItem disablePadding>
      <ListItemButton selected={selectedItems.includes(item)} onClick={() => setSelectedItems([item])}>
        <ListItemText>{item.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
