import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, RemoveCircleOutlineOutlinedIcon } from '../imports';

import StatusIcon from './StatusIcon';
import { iconStyle } from './DataGridRestSort';

interface GridFieldStatusProps {
  useClosed?: boolean;
}

export default function GridFieldStatus(props?: GridFieldStatusProps): GridColDef {
  const width = props?.useClosed ? 76 : 38;
  const padding = props?.useClosed ? '4px' : 'none';

  return {
    field: 'status',
    headerName: '',
    minWidth: width,
    maxWidth: width,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', paddingLeft: padding }}>
          <StatusIcon status={params.row.review?.status ?? ''} size={18} />
          {params.row.closed && (
            <RemoveCircleOutlineOutlinedIcon color="error" sx={{ ...iconStyle, marginLeft: '12px' }} />
          )}
        </Box>
      );
    },
  };
}
