import { MouseEvent } from 'react';

import { Button, CircularProgress, ButtonProps } from '../imports';

interface LoadingButtonProps extends ButtonProps {
  text: string;
  sendingData: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function LoadingButton(props: LoadingButtonProps) {
  const { sendingData, ...rest } = props;

  return (
    <Button
      {...rest}
      variant={props.variant ? props.variant : 'contained'}
      disabled={props.disabled || props.sendingData}
      onClick={
        props.onClick
          ? props.onClick
          : (e: MouseEvent<HTMLButtonElement>) => {
              e.currentTarget &&
                e.currentTarget.form &&
                e.currentTarget.form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
            }
      }
      sx={{ ...props.sx, position: 'relative' }}
    >
      {props.text}
      {props.sendingData && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            left: 'calc(50% - 12px)',
          }}
        />
      )}
    </Button>
  );
}
