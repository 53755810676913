import { createContext, ReactNode, useContext, useState } from 'react';

import SecondaryMenuItem from '../models/SecondaryMenu/SecondaryMenuItem';
import { SecondaryMenuType } from '../models/SecondaryMenu/SecondaryMenuType';

import { SecondaryMenuContextDataInit } from '../constants/SecondaryMenu/SecondaryMenuContextDataInit';

import usePageConfig from './usePageConfig';

const SecondaryMenuContext = createContext(SecondaryMenuContextDataInit);

export function SecondaryMenuProvider({ children }: { children: ReactNode }) {
  const [type, setType] = useState<SecondaryMenuType>('none');
  const [items, setItems] = useState<SecondaryMenuItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<SecondaryMenuItem[]>([]);

  const { currentPage, title } = usePageConfig();

  const pageTitle = title || (type === 'single' && selectedItems.length && selectedItems[0].name) || currentPage.name;

  return (
    <SecondaryMenuContext.Provider
      value={{
        type,
        setType,
        items,
        setItems,
        selectedItems,
        setSelectedItems,
        pageTitle,
      }}>
      {children}
    </SecondaryMenuContext.Provider>
  );
}

export default function useSecondaryMenu() {
  return useContext(SecondaryMenuContext);
}
