import { CircleFlag } from 'react-circle-flags';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '../imports';

interface GridFieldCountryNameProps {
  field?: string;
  headerName?: string;
  codeField?: string;
}

export default function GridFieldCountryName(props?: GridFieldCountryNameProps): GridColDef {
  return {
    field: props?.field ?? 'countryName',
    headerName: props?.headerName ?? 'Country',
    flex: 14,
    renderCell: (params: GridRenderCellParams) => {
      return (
        params.row[props?.field ?? 'countryName'] && (
          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <CircleFlag
              countryCode={String(params.row[props?.codeField ?? 'countryCode']).toLowerCase()}
              title={params.row.countryName}
              height={18}
            />
            <span style={{ marginLeft: '8px', lineHeight: '18px' }}>{params.row[props?.field ?? 'countryName']}</span>
          </Box>
        )
      );
    },
  };
}
