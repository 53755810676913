import { useContext, createContext, ReactNode } from 'react';
import axios from 'axios';

import AuthContextData from '../models/auth/AuthContextData';
import UserLoginData from '../models/user/UserLoginData';
import UserInfo from '../models/user/UserInfo';

import { useLocalStorage } from './useLocalStorage';
import { ApiLogin } from '../constants/endpoints';
import { UserInfoInit } from '../constants/UserInfoInit';
import { UrlDashboard } from '../constants/urls';
import {UserRoleApi} from "../constants/utils";

let acd: AuthContextData = {
  user: UserInfoInit,
  logout: () => {},
  login(): Promise<boolean> {
    return Promise.resolve(false);
  },
};

const AuthContext = createContext(acd);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useLocalStorage('user', UserInfoInit);

  const login = async (data: UserLoginData) => {
    return axios
      .post(ApiLogin, {
        email: data.email,
        password: data.password,
        origin: 'admin'
      })
      .then((response) => {
        const userInfo: UserInfo = {
          id: response.data.data.id as number,
          token: ('Bearer ' + response.data.data.token) as string,
          name: response.data.data.name as string,
          email: response.data.data.email as string,
          role: response.data.data.role as string,
          supported_locales: response.data.data.supported_locales,
        };

        if (userInfo.role === UserRoleApi) {
          throw new Error('');
        }

        setUser(userInfo);

        return Promise.resolve(true);
      })
      .catch(() => {
        return Promise.resolve(false);
      });
  };

  const logout = () => {
    setUser(acd.user);
    window.history.pushState({}, '', UrlDashboard);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
