import { createTheme } from '../imports';

export const GlobalTheme = createTheme({
  palette: {
    background: {
      default: '#EFEFEF',
    },
    primary: {
      main: '#2589CC',
    },
    secondary: {
      main: '#1F364D',
    },
    success: {
      main: '#008000',
    },
    warning: {
      main: '#ffa500ff',
    },
  },
  typography: {
    fontFamily: 'Arial',
    fontSize: 13,
  },
});
