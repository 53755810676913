import { useNavigate } from 'react-router-dom';

import { AccountCircleRoundedIcon, Box, Breadcrumbs, Button, Typography, Link } from '../imports';

import Page from '../models/PageConfig/Page';

import { UrlProfile } from '../constants/urls';

import { useAuth } from '../services/useAuth';
import usePageConfig from '../services/usePageConfig';
import useSecondaryMenu from '../services/useSecondaryMenu';

export default function Header() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const secondaryMenu = useSecondaryMenu();
  let { currentPage, setTitle, pagePath } = usePageConfig();

  document.title = 'Travel Database - ' + secondaryMenu.pageTitle;

  function getPagePath(page: Page) {
    let path = '';

    for (let item of pagePath) {
      path += '/' + item.path;

      if (item === page) {
        break;
      }
    }

    return path;
  }

  if (secondaryMenu.type === 'single' && secondaryMenu.selectedItems.length) {
    pagePath = [...pagePath, currentPage];
  }

  return (
    <Box width="100%" overflow="auto" mb={'33px'}>
      <Box sx={{ float: 'left', paddingTop: '5px' }}>
        <Breadcrumbs separator="•">
          {pagePath.map((page, index) => (
            <Link
              key={index}
              component="button"
              underline="hover"
              fontSize="18px"
              color="grey"
              onClick={() => {
                setTitle('');
                secondaryMenu.setSelectedItems([]);
                navigate(getPagePath(page));
              }}>
              <Typography fontSize="18px">{page.name}</Typography>
            </Link>
          ))}
          <Typography color="black" fontSize="18px">
            {secondaryMenu.pageTitle}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ float: 'right' }}>
        <Button onClick={() => navigate(UrlProfile)} variant="text" color="inherit" sx ={{boxShadow: 0}}>
          <Typography fontSize="14px" mr={1}>
            {user.name}
          </Typography>
          <AccountCircleRoundedIcon
            fontSize="medium"
            sx={{ color: '#EFEFEF', backgroundColor: '#0C1F31', borderRadius: 3 }}
          />
        </Button>
      </Box>
    </Box>
  );
}
