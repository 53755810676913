export const ApiLogin = process.env.REACT_APP_API_URL + '/api/users/login';
export const ApiUsers = process.env.REACT_APP_API_URL + '/api/users';
export const ApiContinents = process.env.REACT_APP_API_URL + '/api/continents';
export const ApiContinentsSearch = process.env.REACT_APP_API_URL + '/api/continents/search';
export const ApiCountries = process.env.REACT_APP_API_URL + '/api/countries';
export const ApiCountriesSearch = process.env.REACT_APP_API_URL + '/api/countries/search';
export const ApiCities = process.env.REACT_APP_API_URL + '/api/cities';
export const ApiCitiesSearch = process.env.REACT_APP_API_URL + '/api/cities/search';
export const ApiCarriers = process.env.REACT_APP_API_URL + '/api/carriers';
export const ApiCarriersSearch = process.env.REACT_APP_API_URL + '/api/carriers/search';
export const ApiEquipments = process.env.REACT_APP_API_URL + '/api/equipments';
export const ApiManufacturersSearch = process.env.REACT_APP_API_URL + '/api/manufacturers/search';
export const ApiLocations = process.env.REACT_APP_API_URL + '/api/locations';
export const ApiCodes = process.env.REACT_APP_API_URL + '/api/codes';
export const ApiRefsTimezones = process.env.REACT_APP_API_URL + '/api/refs/timezone';
export const ApiUsersResetPasswordSendEmail = process.env.REACT_APP_API_URL + '/api/users/password-email';
export const ApiUsersResetPasswordReset = process.env.REACT_APP_API_URL + '/api/users/password-reset';
export const ApiUsersChangePassword = process.env.REACT_APP_API_URL + '/api/users/password-change';
export const ApiDashboard = process.env.REACT_APP_API_URL + '/api/dashboard';
export const ApiUsersVerifyEmail = process.env.REACT_APP_API_URL + '/api/users/verify-email';
