import { useState, useEffect, FormEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AccountCircleRoundedIcon,
  Alert,
  Avatar,
  Box,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LockOutlinedIcon,
  OutlinedInput,
  Typography,
  VisibilityIcon,
  VisibilityOffIcon,
} from '../../imports';

import EmailTextField from '../../components/EmailTextField';
import LoadingButton from '../../components/LoadingButton';

import { useAuth } from '../../services/useAuth';
import email_validate from '../../utils/validators/email';

import { UrlDashboard, UrlResetPassword } from '../../constants/urls';

interface LoginProps {
  redirectTo?: string;
}

export default function Login({ redirectTo }: LoginProps) {
  const [email, setEmail] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((prevState) => !prevState);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    if (email_validate(email)) {
      return;
    }

    if (!loading) {
      setLoading(true);
    }

    const error = login({
      email: email,
      password: formData.get('password') as string,
    });

    error.then((loginResult) => {
      setLoading(false);
      setShowError(!loginResult);
      if (loginResult) {
        navigate(redirectTo ? redirectTo : UrlDashboard);
      }
    });
  };

  useEffect(() => {
    document.title = 'Travel Database - Login';
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#d50000' }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Database
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {showError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              This combination of email and password is wrong
            </Alert>
          )}

          <EmailTextField
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={setEmail}
            InputProps={{
              endAdornment: <AccountCircleRoundedIcon sx={{ marginRight: 1, color: 'gray' }} />,
            }}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="password" required>
              Password
            </InputLabel>
            <OutlinedInput
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ color: 'gray' }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Box>
            <Chip
              label="FORGOT YOUR PASSWORD?"
              variant="outlined"
              sx={{
                border: 'none',
                color: 'primary.main',
                float: 'right',
              }}
              onClick={() => navigate(UrlResetPassword)}
              onMouseDown={() => navigate(UrlResetPassword)}
            />
          </Box>

          <Box sx={{ mt: 5 }}>
            <LoadingButton type="submit" text="Sign In" sendingData={loading} fullWidth />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
