import { useState } from 'react';

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '../../imports';

import FilterData from '../../models/Filter/FilterData';

import { ucfirst } from '../../utils/utils';

const types = ['airport', 'bus', 'port', 'rail'];

export default function Type({ data }: { data: FilterData }) {
  const [operator, setOperator] = useState('IN');
  const [values, setValues] = useState<string[]>([]);

  data.operator = operator;

  if (operator === 'IN') {
    data.operatorLabel = 'is';
  } else {
    data.operatorLabel = 'is not';
  }

  data.value = values;
  data.valueLabel = values.map((value) => ucfirst(value));

  function changeValues(event: SelectChangeEvent<typeof values>) {
    const {
      target: { value },
    } = event;

    setValues(typeof value === 'string' ? value.split(',') : value);
  }

  function deleteValue(value: string) {
    setValues(values.filter((v) => v !== value));
  }

  return (
    <RadioGroup name="inclusion" value={operator} onChange={(event) => setOperator(event.target.value)}>
      <FormControlLabel value="IN" control={<Radio />} label="is" />
      <FormControl>
        <InputLabel id="filter-label">Type</InputLabel>
        <Select
          labelId="filter-label"
          multiple
          value={values}
          onChange={changeValues}
          input={<OutlinedInput label="Type" />}
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value) => (
                <Chip
                  key={value}
                  label={ucfirst(value)}
                  onDelete={() => deleteValue(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
        >
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              {ucfirst(type)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel value="NOT IN" control={<Radio />} label="is not" />
    </RadioGroup>
  );
}
