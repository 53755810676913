import {
  AirplaneTicketIcon,
  HomeIcon,
  LanguageIcon,
  PeopleIcon,
  PublicIcon,
  ShareIcon,
  SpeedIcon,
  TuneIcon,
} from '../../imports';

import CarriersEdit from '../../pages/Carriers/CarriersEdit';
import CarriersList from '../../pages/Carriers/CarriersList';
import CitiesEdit from '../../pages/Cities/CitiesEdit';
import CitiesList from '../../pages/Cities/CitiesList';
import CodesEdit from '../../pages/Codes/CodesEdit';
import CodesList from '../../pages/Codes/CodesList';
import ContinentsEdit from '../../pages/Continents/ContinentsEdit';
import ContinentsList from '../../pages/Continents/ContinentsList';
import CountriesEdit from '../../pages/Countries/CountriesEdit';
import CountriesList from '../../pages/Countries/CountriesList';
import Dashboard from '../../pages/Dashboard';
import EquipmentsEdit from '../../pages/Equipments/EquipmentsEdit';
import EquipmentsList from '../../pages/Equipments/EquipmentsList';
import LocationsList from '../../pages/Locations/LocationsList';
import LocationsEdit from '../../pages/Locations/LocationsEdit';
import NotFound from '../../pages/NotFound';
import Profile from '../../pages/Profile';
import UsersEdit from '../../pages/Users/UsersEdit';
import UsersList from '../../pages/Users/UsersList';

import PageConfig from '../../models/PageConfig/PageConfig';

import {
  UrlContinents,
  UrlCountries,
  UrlDashboard,
  UrlEquipments,
  UrlUsers,
  UrlProfile,
  UrlCarriers,
  UrlLocations,
  UrlCities,
  UrlCodes,
} from '../urls';

import { UserRoleUser, UserRoleAdmin } from '../utils';

let config: PageConfig = {
  error: {
    name: 'Not Found',
    path: '*',
    element: <NotFound />,
    role: UserRoleUser,
  },
  dashboard: {
    name: 'Dashboard',
    path: UrlDashboard,
    icon: <SpeedIcon />,
    element: <Dashboard />,
    role: UserRoleUser,
  },
  carriers: {
    name: 'Carriers',
    path: UrlCarriers,
    icon: <ShareIcon />,
    element: <CarriersList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Carrier edit',
        path: ':carrierId',
        element: <CarriersEdit />,
        role: UserRoleUser,
      },
    },
  },
  locations: {
    name: 'Locations',
    path: UrlLocations,
    icon: <AirplaneTicketIcon />,
    element: <LocationsList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Location edit',
        path: ':locationId',
        element: <LocationsEdit />,
        role: UserRoleUser,
      },
    },
  },
  codes: {
    name: 'Codes',
    path: UrlCodes,
    icon: <TuneIcon />,
    element: <CodesList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Code edit',
        path: ':codeId',
        element: <CodesEdit />,
        role: UserRoleUser,
      },
    },
  },
  continents: {
    name: 'Continents',
    path: UrlContinents,
    icon: <PublicIcon />,
    element: <ContinentsList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Continent edit',
        path: ':continentId',
        element: <ContinentsEdit />,
        role: UserRoleUser,
      },
    },
  },
  equipments: {
    name: 'Equipments',
    path: UrlEquipments,
    icon: <ShareIcon />,
    element: <EquipmentsList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Equipments edit',
        path: ':equipmentId',
        element: <EquipmentsEdit />,
        role: UserRoleUser,
      },
    },
  },
  countries: {
    name: 'Countries',
    path: UrlCountries,
    icon: <LanguageIcon />,
    element: <CountriesList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Country edit',
        path: ':countryId',
        element: <CountriesEdit />,
        role: UserRoleUser,
      },
    },
  },
  cities: {
    name: 'Cities',
    path: UrlCities,
    icon: <HomeIcon />,
    element: <CitiesList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'City edit',
        path: ':cityId',
        element: <CitiesEdit />,
        role: UserRoleUser,
      },
    },
  },
  users: {
    name: 'Users',
    path: UrlUsers,
    icon: <PeopleIcon />,
    element: <UsersList />,
    role: UserRoleAdmin,
    children: {
      edit: {
        name: 'User edit',
        path: ':userId',
        element: <UsersEdit />,
        role: UserRoleAdmin,
      },
    },
  },
  profile: {
    name: 'Profile',
    path: UrlProfile,
    icon: <PeopleIcon />,
    element: <Profile />,
    role: UserRoleUser,
  },
};

Object.keys(config).forEach(
  (key) => (config[key].path = config[key].path.startsWith('/') ? config[key].path.substring(1) : config[key].path)
);

export const pageConfig = config;
