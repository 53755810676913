import { ReactNode, useState } from 'react';

import {
  Box,
  BoxProps,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FilterAltOutlinedIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
} from '../../imports';

import Status from './Status';
import Type from './Type';
import Country from './Country';
import City from './City';
import Update from './Update';

import FilterData from '../../models/Filter/FilterData';

interface FilterDataList {
  [key: string]: FilterData;
}

interface FiltersProps {
  filters: string;
  setFiltersData: (data: FilterData[]) => void;
}

const filterNames: { [key: string]: string } = {
  status: 'Status',
  type: 'Type',
  country: 'Country',
  city: 'City',
  updated_at: 'Last updated',
};

const availableFilters: { [key: string]: FilterData } = Object.keys(filterNames).reduce(
  (filterDataList, filterField) => ({
    ...filterDataList,
    [filterField]: {
      name: filterNames[filterField],
      field: filterField,
      operator: '',
      operatorLabel: '',
      value: '',
      valueLabel: '',
    },
  }),
  {}
);

const grayColor = '#959595';

function emptyFilterDataValue(value: string | string[]): boolean {
  return !((typeof value === 'string' && value) || value.length);
}

export default function Filters(props: FiltersProps) {
  const [filterDataList, setFilterDataList] = useState<FilterDataList>(
    props.filters.split(',').reduce(
      (filterList, filterField) => ({
        ...filterList,
        [filterField]: availableFilters[filterField],
      }),
      {}
    )
  );

  const [filterListOpen, setFilterListOpen] = useState(false);
  const [filterListAnchor, setFilterListAnchor] = useState<HTMLElement | null>(null);
  const [openFilterAnchor, setOpenFilterAnchor] = useState<HTMLElement | null>(null);
  const [editedFilterData, setEditedFilterData] = useState<FilterData | null>(null);

  function updateFilters() {
    const newFilterDataList = { ...filterDataList };

    if (editedFilterData) {
      newFilterDataList[editedFilterData.field] = editedFilterData;
    }

    setFilterDataList(newFilterDataList);

    props.setFiltersData(
      Object.keys(newFilterDataList)
        .filter((filterField) => !emptyFilterDataValue(newFilterDataList[filterField].value))
        .map((filterField) => newFilterDataList[filterField])
    );
  }

  function openFilterList(anchor: HTMLElement) {
    setFilterListOpen(true);
    setFilterListAnchor(anchor);
  }

  function closeFilterList() {
    setFilterListOpen(false);
    setFilterListAnchor(null);
    setOpenFilterAnchor(null);
    setEditedFilterData(null);
  }

  function openFilter(filterField: string, anchor: HTMLElement) {
    setOpenFilterAnchor(anchor);
    setEditedFilterData({ ...filterDataList[filterField] });
  }

  function applyFilter() {
    updateFilters();
    closeFilterList();
  }

  function cancelFilter() {
    closeFilterList();
  }

  function deleteFilter(filterField: string) {
    filterDataList[filterField].value = '';
    updateFilters();
  }

  let i = 1;

  return (
    <Stack direction="row" spacing={1} sx={{ color: grayColor, verticalAlign: 'middle' }}>
      <FilterAltOutlinedIcon sx={{ fontSize: 30 }} />

      {Object.keys(filterDataList)
        .filter((filterField) => !emptyFilterDataValue(filterDataList[filterField].value))
        .map((filterField) => {
          const filterData = filterDataList[filterField];

          const boxProps: BoxProps = {
            component: 'span',
            sx: {
              color: grayColor,
            },
          };

          return (
            <Chip
              key={filterField}
              variant="filter"
              label={
                <>
                  {filterData.name}
                  <Box {...boxProps}>{' ' + filterData.operatorLabel + ' '}</Box>
                  {typeof filterData.valueLabel === 'string'
                    ? filterData.valueLabel
                    : filterData.valueLabel
                        .map<ReactNode>((label) => label)
                        .reduce((prev, curr) => [
                          prev,
                          <Box key={i++} {...boxProps}>
                            {' or '}
                          </Box>,
                          curr,
                        ])}
                </>
              }
              onDelete={() => deleteFilter(filterField)}
            />
          );
        })}

      <Chip variant="filter" label="+" onClick={(event) => openFilterList(event.currentTarget)} />

      <Popover
        open={filterListOpen}
        onClose={closeFilterList}
        anchorEl={filterListAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
      >
        <List disablePadding dense>
          {Object.keys(filterDataList).map((filterField) => {
            const filterData = filterDataList[filterField];

            return (
              <ListItem key={filterField} disablePadding>
                <ListItemButton
                  onClick={(event) => openFilter(filterField, event.currentTarget)}
                  selected={filterField === editedFilterData?.field}
                  disabled={!emptyFilterDataValue(filterData.value)}
                  sx={{ '&.Mui-selected': { backgroundColor: '#BFDAEF' } }}
                >
                  <ListItemText primary={filterData.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Popover>

      <Popover
        open={!!editedFilterData}
        onClose={closeFilterList}
        anchorEl={openFilterAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 0, horizontal: -5 }}
      >
        {editedFilterData && (
          <Card variant="filter">
            <CardHeader title={editedFilterData.name} />

            <CardContent>
              {editedFilterData.field === 'status' && <Status data={editedFilterData} />}
              {editedFilterData.field === 'type' && <Type data={editedFilterData} />}
              {editedFilterData.field === 'country' && <Country data={editedFilterData} />}
              {editedFilterData.field === 'city' && <City data={editedFilterData} />}
              {editedFilterData.field === 'updated_at' && <Update data={editedFilterData} />}

              <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <Button className="action" color="primary" variant="contained" size="small" onClick={applyFilter}>
                  Apply
                </Button>

                <Button className="action" color="primary" variant="outlined" size="small" onClick={cancelFilter}>
                  Cancel
                </Button>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Popover>
    </Stack>
  );
}
