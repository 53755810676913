import { Button } from '../imports';
import { useEffect, useState } from 'react';
import { get_google_url } from '../utils/utils';

interface OpenMapButtonProps {
  latitude?: string;
  longitude?: string;
  address?: string;
  zoom?: string;
}

export default function OpenMapButton(props: OpenMapButtonProps) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(get_google_url(props.latitude, props.longitude, props.address, props.zoom));
  }, [props.latitude, props.longitude, props.address, props.zoom]);

  return (
    <>
      {url !== '' && (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => window.open(url, '_blank')}
          sx={{ maxHeight: '22px', minWidth: '91px' }}
        >
          Open map
        </Button>
      )}
    </>
  );
}
