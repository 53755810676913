import axios from 'axios';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountCircleRoundedIcon, Alert, ArrowBackIcon, Box, Button, Container, Typography } from '../../../imports';

import EmailTextField from '../../../components/EmailTextField';
import LoadingButton from '../../../components/LoadingButton';

import { UrlLogin } from '../../../constants/urls';
import { PasswordResetLinkExpires } from '../../../constants/utils';
import { ApiUsersResetPasswordSendEmail } from '../../../constants/endpoints';

import email_validate from '../../../utils/validators/email';
import useAbortSignal from '../../../services/useAbortSignal';
import { useDataSendStatus } from '../../../services/useDataSendStatus';

export default function ResetPasswordEmail() {
  const [email, setEmail] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSent, setDataSent] = useState(false);

  const navigate = useNavigate();
  const { checkResponseError } = useDataSendStatus();
  const abortSignal = useAbortSignal();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email_validate(email)) {
      return;
    }

    setShowError(false);
    setLoading(true);
    axios
      .post(
        ApiUsersResetPasswordSendEmail,
        {
          email: email,
        },
        { signal: abortSignal }
      )
      .then(() => {
        setDataSent(true);
      })
      .catch((e) => {
        checkResponseError(e);
        setShowError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = 'Travel Database - Reset password';
  }, []);

  return (
    <>
      <Button variant="contained" startIcon={<ArrowBackIcon />} sx={{ margin: 3 }} onClick={() => navigate(UrlLogin)}>
        Back
      </Button>
      <Container component="main" maxWidth="xs">
        <Box component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography variant="h5" sx={{ marginBottom: 3 }}>
              Reset password
            </Typography>

            {showError && (
              <Alert severity="error" sx={{ mb: 3 }}>
                Error resetting password
              </Alert>
            )}
            {dataSent && (
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                An email with a link to reset your password was sent to you. It is valid for {PasswordResetLinkExpires}{' '}
                hours.
              </Typography>
            )}
            {!dataSent && (
              <EmailTextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(value: string) => setEmail(value)}
                InputProps={{
                  endAdornment: <AccountCircleRoundedIcon sx={{ marginRight: 1, color: 'gray' }} />,
                }}
              />
            )}

            <Box sx={{ mt: 3 }}>
              {dataSent ? (
                <Button variant="contained" onClick={() => navigate(UrlLogin)}>
                  Back to Login
                </Button>
              ) : (
                <LoadingButton type="submit" text="Send" sendingData={loading} />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
