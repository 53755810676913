import { format } from 'date-fns';

import Continent from '../models/Continent';

import { DateFormatApiFull } from './utils';

export const ContinentInit: Continent = {
  id: '0',
  identifier: '',
  name: '',
  code: '',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  translations: {},
};
