import { Outlet } from 'react-router-dom';

import { SecondaryMenuProvider } from '../services/useSecondaryMenu';
import { PageConfigProvider } from '../services/usePageConfig';
import { DataSendStatusProvider } from '../services/useDataSendStatus';

export default function Root() {
  return (
    <PageConfigProvider>
      <SecondaryMenuProvider>
        <DataSendStatusProvider>
          <Outlet />
        </DataSendStatusProvider>
      </SecondaryMenuProvider>
    </PageConfigProvider>
  );
}
