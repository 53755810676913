import { Box, FindInPageIcon, Typography } from '../../imports';

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
      }}>
      <FindInPageIcon style={{ color: 'white', fontSize: 90 }} />
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
    </Box>
  );
}
