import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import {
  AdapterDateFns,
  Box,
  Card,
  CardContent,
  CardHeader,
  DatePicker,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '../../../imports';

import AutocompleteLoading from '../../../components/Autocomplete/AutocompleteLoading';
import CodeCconField from '../../../components/CodeCconField';
import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import ImageField from '../../../components/ImageField';
import LoadingPage from '../../../components/LoadingPage';
import ReviewCard from '../../../components/ReviewCard';
import SimpleNumberField from '../../../components/SimpleNumberField';
import StatusIcon from '../../../components/StatusIcon';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsIcon from '../../../components/TranslationsIcon';

import AutocompleteOption from '../../../models/AutocompleteOption';
import Carrier from '../../../models/Carrier';
import CarrierFormDataErrors from '../../../models/CarrierFormDataErrors';
import ReviewData from '../../../models/ReviewData';
import { Translations } from '../../../models/Translations';

import { ApiCarriers, ApiCitiesSearch, ApiCountriesSearch } from '../../../constants/endpoints';
import { CarrierInit } from '../../../constants/CarrierInit';
import { CarrierTypes } from '../../../constants/CarrierTypes';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { DateFormatApi, DateFormatInput, LocaleEn } from '../../../constants/utils';
import { UrlCarriers } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { ucfirst } from '../../../utils/utils';
import { useValidateField } from '../../../services/useValidateField';
import without_diacritics_validate from '../../../utils/validators/without_diacritics';
import alpha_num_validate from '../../../utils/validators/alpha_num';
import integer_validate from '../../../utils/validators/integer';
import required_validate from '../../../utils/validators/required';
import size_validate from '../../../utils/validators/size';
import size_max_validate from '../../../utils/validators/size_max';
import url_validate from '../../../utils/validators/url';

export default function CarriersEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Carrier>(CarrierInit);
  const [loadedFormData, setLoadedFormData] = useState<Carrier>(CarrierInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [founded, setFounded] = useState<Date | null>(null);
  const [ceased, setCeased] = useState<Date | null>(null);

  const [validateField, setFieldError, formErrors] = useValidateField(formData, {} as CarrierFormDataErrors);
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { carrierId } = useParams();
  const axiosHelper = useAxios();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const updateLoadedFormData = useCallback(
    (data: Carrier) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name || data.identifier);

      if (data.founded) {
        setFounded(new Date(data.founded));
      } else {
        setFounded(null);
      }

      if (data.ceased) {
        setCeased(new Date(data.ceased));
      } else {
        setCeased(null);
      }
    },
    [setTitle]
  );

  const onHeadquarterCountryChangeValue = (newValue: AutocompleteOption | null) => {
    if (newValue) {
      setFormData((prevState) => ({
        ...prevState,
        headquarterCountry: newValue.id,
        headquarterCountryName: newValue.name,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, headquarterCountry: '', headquarterCountryName: '' }));
    }

    onHeadquarterCityChangeValue(null);
  };

  const onHeadquarterCityChangeValue = (newValue: AutocompleteOption | null) => {
    if (newValue) {
      setFormData((prevState) => ({
        ...prevState,
        headquarterCity: newValue.id,
        headquarterCityName: newValue.name,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, headquarterCity: '', headquarterCityName: '' }));
    }
  };

  const onTypeChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, type: event.target.value as string }));
  };

  function getDataFromResponse(response: any): Carrier {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '',
      iata: (response.iata as string) ?? '',
      icao: (response.icao as string) ?? '',
      bsp: (response.bsp as string) ?? '',
      ccon: (response.ccon as string) ?? '',
      company: (response.company as string) ?? '',
      name: (response.name as string) ?? '',
      nameWithoutDiacritics: (response.nameWithoutDiacritics as string) ?? '',
      type: (response.type as string) ?? '',
      founded: (response.founded as string) ?? '',
      ceased: (response.ceased as string) ?? '',
      ceasedReason: (response.ceasedReason as string) ?? '',
      numberOfStations: (response.numberOfStations as string) ?? '',
      headquarterCountry: (response.headquarterCountry as string) ?? '',
      headquarterCountryName: (response.headquarterCountryName as string) ?? '',
      headquarterCity: (response.headquarterCity as string) ?? '',
      headquarterCityName: (response.headquarterCityName as string) ?? '',
      website: (response.weblinks.website as string) ?? '',
      generalConditionsOfCarriage: (response.weblinks.generalConditionsOfCarriage as Translations) ?? {},
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      logo: (response.logo as string) ?? '',
      mime: (response.mime as string) ?? '',
      created: (response.created as string) ?? '',
      updated: (response.updated as string) ?? '',
      createdBy: (response.createdBy as string) ?? '',
      updatedBy: (response.updatedBy as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
    };
  }

  const handleTranslationsChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      generalConditionsOfCarriage: data,
    }));
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const handleChangeDate = (name: string, value: Date | null) => {
    let dateValue: string | null = null;

    if (value) {
      try {
        dateValue = format(value, DateFormatApi);
      } catch (e) {
        value = null;
        dateValue = null;
      }
    }

    if (name === 'founded') {
      setFounded(value);
    } else if (name === 'ceased') {
      setCeased(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: dateValue,
    }));
  };

  const validate = (): boolean => {
    let valid = true;
    valid =
      validateField('iata', (value: string) => size_validate(value, 2)) &&
      validateField('iata', alpha_num_validate) &&
      valid;

    valid =
      validateField('icao', (value: string) => size_validate(value, 3)) &&
      validateField('icao', alpha_num_validate) &&
      valid;

    valid =
      validateField('bsp', (value: string) => size_validate(value, 3)) &&
      validateField('bsp', integer_validate) &&
      valid;

    valid = validateField('type', required_validate, false) && valid;

    valid =
      validateField('name', required_validate, false) &&
      validateField('name', (value: string) => size_max_validate(value, 255), false) &&
      valid;

    valid = validateField('nameWithoutDiacritics', without_diacritics_validate) && valid;

    valid = validateField('website', url_validate) && valid;

    for (const locale in formData.generalConditionsOfCarriage) {
      const value = formData.generalConditionsOfCarriage[locale];
      const error = url_validate(value);

      valid = setFieldError('generalConditionsOfCarriage', value, true, error) && valid;

      if (error !== '') {
        break;
      }
    }

    return valid;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let url: string;
    if (action === 'edit') {
      url = ApiCarriers + '/' + carrierId;
    } else {
      url = ApiCarriers;
    }

    axiosHelper
      .post(
        url,
        {
          ...formData,
          review: { status: formData.status, notes: formData.notes },
          weblinks: {
            website: formData.website,
            generalConditionsOfCarriage: formData.generalConditionsOfCarriage,
          },
          logo: formData.logo instanceof File ? formData.logo : null,
          logo_delete: !formData.logo,
        },
        { 'Content-Type': 'multipart/form-data' }
      )
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.carriers)[0]));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Carrier created' });
          navigate(UrlCarriers);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (carrierId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiCarriers + '/' + carrierId)
        .then((response) => {
          updateLoadedFormData(
            getDataFromResponse((Object.values(response.data.data.carriers)[0] as Carrier) ?? CarrierInit)
          );
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add carrier');
    }
  }, [carrierId, checkResponseError, axiosHelper, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (carrierId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [carrierId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiCarriers + '/' + carrierId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Carrier deleted' });
          navigate(UrlCarriers);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Carrier" action={<StatusIcon status={loadedFormData.status ?? ''} />} />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="iata"
                          id="iata"
                          label="IATA code"
                          type="text"
                          value={formData.iata}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 2 }}
                          error={!!formErrors.iata}
                          helperText={formErrors.iata}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="icao"
                          id="icao"
                          label="ICAO code"
                          type="text"
                          value={formData.icao}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 3 }}
                          error={!!formErrors.icao}
                          helperText={formErrors.icao}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="bsp"
                          id="bsp"
                          label="BSP prefix"
                          type="text"
                          value={formData.bsp}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 3 }}
                          error={!!formErrors.bsp}
                          helperText={formErrors.bsp}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={1} xs={1}>
                      <CodeCconField value={formData.ccon} />
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl
                    margin="dense"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    fullWidth
                    error={!!formErrors.type}
                  >
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      value={formData.type}
                      label="Type"
                      name="type"
                      onChange={onTypeChange}
                      onBlur={() => validate()}
                      error={!!formErrors.type}
                    >
                      {CarrierTypes.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.type}>
                            {ucfirst(v)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!formErrors.type && <FormHelperText>{formErrors.type}</FormHelperText>}
                  </FormControl>

                  <Divider />

                  <FormControl margin="dense" fullWidth sx={{ marginTop: 2 }}>
                    <TextField
                      fullWidth
                      name="company"
                      id="company"
                      label="Company"
                      type="text"
                      value={formData.company}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.company}
                      helperText={formErrors.company}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="name"
                      id="name"
                      label="Name"
                      type="text"
                      value={formData.name}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="nameWithoutDiacritics"
                      id="nameWithoutDiacritics"
                      label="Name without diacritical marks"
                      type="text"
                      value={formData.nameWithoutDiacritics}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.nameWithoutDiacritics}
                      helperText={formErrors.nameWithoutDiacritics}
                    />
                  </FormControl>

                  <Divider />

                  <Grid container columns={2} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="numberOfStations"
                        id="numberOfStations"
                        label="No. of stations"
                        type="text"
                        value={formData.numberOfStations}
                        onChange={onChange}
                        onBlur={() => validate()}
                        onlyInteger={true}
                        error={!!formErrors.numberOfStations}
                        helperText={formErrors.numberOfStations}
                      />
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Founded"
                            value={founded}
                            onChange={(value) => handleChangeDate('founded', value)}
                            maxDate={ceased ? ceased : new Date()}
                            format={DateFormatInput}
                            slotProps={{
                              textField: {
                                error: !!formErrors.founded,
                                helperText: formErrors.founded,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Ceased operations"
                            value={ceased}
                            onChange={(value) => handleChangeDate('ceased', value)}
                            minDate={founded}
                            maxDate={new Date()}
                            format={DateFormatInput}
                            slotProps={{
                              textField: {
                                error: !!formErrors.ceased,
                                helperText: formErrors.ceased,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
                    <InputLabel htmlFor="ceasedReason">Ceased operations due to</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="ceasedReason"
                      id="ceasedReason"
                      label="Ceased operations due to"
                      type="text"
                      value={formData.ceasedReason}
                      onChange={onChange}
                    />
                  </FormControl>
                </CardContent>
              </Card>

              <Card variant="island">
                <CardHeader title="Weblinks" />
                <CardContent>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="website"
                      id="website"
                      label="Website"
                      type="text"
                      value={formData.website}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.website}
                      helperText={formErrors.website}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="generalConditionsOfCarriage">General conditions of carriage</InputLabel>
                    <OutlinedInput
                      fullWidth
                      readOnly
                      name="generalConditionsOfCarriage"
                      id="generalConditionsOfCarriage"
                      label="General conditions of carriage"
                      type="text"
                      value={
                        (formData.generalConditionsOfCarriage && formData.generalConditionsOfCarriage[LocaleEn]) || ''
                      }
                      onClick={handleToggleDrawer(true)}
                      onBlur={() => validate()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <TranslationsIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        '& .MuiInputBase-input': {
                          color: 'black',
                        },
                      }}
                      error={!!formErrors.generalConditionsOfCarriage}
                    />
                    {!!formErrors.generalConditionsOfCarriage && (
                      <FormHelperText>{formErrors.generalConditionsOfCarriage}</FormHelperText>
                    )}
                  </FormControl>

                  <TranslationsDrawer
                    name="generalConditionsOfCarriage"
                    label="General conditions of carriage"
                    open={drawer}
                    translations={formData.generalConditionsOfCarriage}
                    onChange={handleTranslationsChange}
                    onClose={() => {
                      setDrawer(false);
                      validate();
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Headquarter" />
                <CardContent>
                  <AutocompleteLoading
                    urlPrefix={ApiCountriesSearch}
                    label="Country"
                    value={formData.headquarterCountry ?? ''}
                    valueName={formData.headquarterCountryName ?? ''}
                    onChangeValue={onHeadquarterCountryChangeValue}
                    loadAll={true}
                  />

                  <AutocompleteLoading
                    urlPrefix={ApiCitiesSearch}
                    label="City"
                    value={formData.headquarterCity ?? ''}
                    valueName={formData.headquarterCityName ?? ''}
                    disabled={!Boolean(formData.headquarterCountry)}
                    parentId={formData.headquarterCountry}
                    onChangeValue={onHeadquarterCityChangeValue}
                  />
                </CardContent>
              </Card>
              <Card variant="island">
                <CardHeader title="Logo" />
                <CardContent sx={{ textAlign: 'center' }}>
                  <ImageField
                    name="logo"
                    data={formData.logo}
                    mime={formData.mime}
                    setData={(file) => setFormData({ ...formData, logo: file })}
                  />
                </CardContent>
              </Card>

              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data: ReviewData) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />

              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="carrier"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
