import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import GridIconEdit from './GridIconEdit';

interface GridFieldActionsProps {
  baseUrlEdit: string;
}

export default function GridFieldActions(props: GridFieldActionsProps): GridColDef {
  return {
    field: 'actions',
    headerName: '',
    minWidth: 76,
    maxWidth: 76,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <GridIconEdit urlEdit={props.baseUrlEdit + '/' + params.id} />
        </>
      );
    },
  };
}
