import { ChangeEvent, useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CloseIcon,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '../imports';

import { LocaleEn } from '../constants/utils';
import { Translations } from '../models/Translations';

import { useAuth } from '../services/useAuth';

interface TranslationsDrawerProps {
  name: string;
  label: string;
  open: boolean;
  globalDesignation?: boolean;
  translations?: Translations;
  // event handlers
  onChange?: (data: Translations) => void;
  onClose?: (open: boolean) => void;
  rows?: number;
}

export default function TranslationsDrawer({
  name,
  label,
  open,
  globalDesignation,
  translations,
  onChange,
  onClose,
  rows,
}: TranslationsDrawerProps) {
  const { user } = useAuth();

  const [data, setData] = useState(translations || {});

  const locales = Object.entries(user.supported_locales).map(([locale, language]) => ({
    code: locale,
    countryCode: locale.substring(3, 5).toLowerCase(),
    languageCode: locale.substring(0, 2).toLowerCase(),
    languageName: language,
  }));
  const defaultLocale = locales.filter((locale) => locale.code === LocaleEn)[0];

  let tmp: Translations = {};
  locales.forEach((locale) => {
    tmp[locale.code] = (translations && translations[locale.code]) ?? '';
  });

  const [globalDesignationValue, setGlobalDesignationValue] = useState(
    globalDesignation !== undefined
      ? Object.values(tmp).filter((value, index, array) => array.indexOf(value) === index).length === 1
      : false
  );

  const handleChangeGlobalDesignation = (checked: boolean): void => {
    setGlobalDesignationValue(checked);

    if (checked) {
      let tmp: Translations = {};
      locales.forEach((locale) => {
        tmp[locale.code] = data[defaultLocale.code] ?? '';
      });
      setData((prevState) => ({ ...prevState, ...tmp }));
    } else {
      setData(translations || {});
    }
  };

  const handleUpdate = (locale: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setData((prevState) => ({ ...prevState, [locale]: value }));
  };

  const handleChange = (): void => {
    onChange && onChange(data);
    onClose && onClose(false);
  };

  const handleCancel = (): void => {
    setData(translations || {});

    globalDesignation !== undefined && setGlobalDesignationValue(globalDesignation);

    onClose && onClose(false);
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    onClose && onClose(open);
  };

  useEffect(() => {
    setData(translations || {});
  }, [translations]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleToggleDrawer(false)}
      PaperProps={{ sx: { backgroundColor: '#EFEFEF', borderRadius: 0, p: 4 } }}
    >
      <Box className="RightDrawerContent">
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 4 }}>
          <Typography fontSize="18px">{label}</Typography>
          <IconButton aria-label="close" size="small" onClick={handleToggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Card
          variant="island"
          sx={{
            marginX: 10,
            paddingTop: 1.5,
            paddingBottom: 2,
            '& .MuiCardContent-root:last-child': { paddingBottom: 0 },
          }}
        >
          <CardContent>
            <FormControl margin="dense" sx={{ marginBottom: 1 }} fullWidth>
              <InputLabel htmlFor={name + '_' + defaultLocale.code}>
                {defaultLocale.languageName + ' / International'}
              </InputLabel>
              <OutlinedInput
                autoFocus
                fullWidth
                multiline={Boolean(rows)}
                rows={rows ?? 1}
                name={name + '_' + defaultLocale.code}
                id={name + '_' + defaultLocale.code}
                label={defaultLocale.languageName + ' / International'}
                type="text"
                value={data[defaultLocale.code] || ''}
                onChange={handleUpdate(defaultLocale.code)}
                endAdornment={
                  <InputAdornment position="end" sx={rows ? { position: 'absolute', top: 20, right: 15 } : {}}>
                    <CircleFlag
                      countryCode={defaultLocale.countryCode}
                      title={defaultLocale.languageName}
                      height={20}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
            {globalDesignation !== undefined && (
              <FormControlLabel
                label="Global designation"
                control={
                  <Checkbox
                    checked={globalDesignationValue}
                    onChange={(event) => handleChangeGlobalDesignation(event.target.checked)}
                  />
                }
              />
            )}
            <Divider />
            {locales
              .filter((locale) => locale !== defaultLocale)
              .map((locale, key) => (
                <FormControl key={key} margin="dense" sx={key === 0 ? { marginTop: 2 } : {}} fullWidth>
                  <InputLabel htmlFor={name + '_' + locale.code}>{locale.languageName}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    multiline={Boolean(rows)}
                    rows={rows ?? 1}
                    name={name + '_' + locale.code}
                    id={name + '_' + locale.code}
                    label={locale.languageName}
                    type="text"
                    value={data[locale.code] || ''}
                    readOnly={globalDesignationValue}
                    onChange={handleUpdate(locale.code)}
                    endAdornment={
                      <InputAdornment position="end" sx={rows ? { position: 'absolute', top: 20, right: 15 } : {}}>
                        <CircleFlag countryCode={locale.countryCode} title={locale.languageName} height={20} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              ))}
          </CardContent>
        </Card>
        <Stack direction="row" ml={1} sx={{ mt: 3, mx: 8 }}>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={handleChange}>
            Change
          </Button>
          <Button variant="elevated" sx={{ ml: 2 }} onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}
