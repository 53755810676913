import { Link } from 'react-router-dom';

import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow } from '../../imports';

import DashboardItem from '../../models/Dashboard/DashboardItem';

interface TableProps {
  data: DashboardItem[];
  user: string;
}

export default function Table({data, user}: TableProps) {
  return (
    <MuiTable>
      <TableHead>
        <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
          <TableCell align="left">Type</TableCell>
          <TableCell align="center">Name</TableCell>
          <TableCell align="right">{user}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row) => (
          <TableRow
            key={row.identifier}
            sx={{ '&:last-child td': { border: 0 } }}
          >
            <TableCell align="left">{row.type}</TableCell>

            <TableCell align="center">
              <Link to={'/' + row.table + '/' + row.identifier}>
                {row.name}
              </Link>
            </TableCell>

            <TableCell align="right">{row.user}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
}
