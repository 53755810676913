import { format } from 'date-fns';

import Country from '../models/Country';

import { DateFormatApiFull } from './utils';

export const CountryInit: Country = {
  id: '0',
  identifier: '',
  name: '',
  official: '',
  aliases: [],
  iso: '',
  continent: '',
  continentName: '',
  alpha3: '',
  numeric: '',
  callingCodes: [],
  demonym: '',
  internetTlds: [],
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  drivingSide: '',
  dateFormat: '',
  status: 'To verify',
  notes: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
  translations: {},
};
