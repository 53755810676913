import { CachedIcon, CheckIcon, Tooltip } from '../imports';

interface StatusIconProps {
  status: string;
  size?: number;
}

export default function StatusIcon(props: StatusIconProps) {
  const iconProps = {
    ...(props.size ? { fontSize: props.size + 'px' } : {})
  };

  return (
    <Tooltip title={props.status}>
      <>
      {props.status === 'Verified' && <CheckIcon sx={iconProps} color="success" />}
      {props.status !== 'Verified' && <CachedIcon sx={iconProps} color="warning" />}
      </>
    </Tooltip>
  );
}
