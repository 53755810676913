import SecondaryMenuContextData from '../../models/SecondaryMenu/SecondaryMenuContextData';

export const SecondaryMenuContextDataInit: SecondaryMenuContextData = {
  type: 'none',
  setType() {},
  items: [],
  setItems() {},
  selectedItems: [],
  setSelectedItems() {},
  pageTitle: '',
};
