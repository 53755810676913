import Page from '../../models/PageConfig/Page';

import { UserRoleUser } from '../utils';

export const PageInit: Page = {
  name: '',
  path: '',
  element: <></>,
  role: UserRoleUser,
};
