import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  CheckIcon,
  Chip,
  ClearIcon,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
} from '../../../imports';

import AutocompleteLoading from '../../../components/Autocomplete/AutocompleteLoading';
import CodeCconField from '../../../components/CodeCconField';
import EditButtons from '../../../components/EditButtons';
import HistoryCard from '../../../components/HistoryCard';
import IdentifierField from '../../../components/IdentifierField';
import LoadingPage from '../../../components/LoadingPage';
import NameField from '../../../components/NameField';
import OpenMapButton from '../../../components/OpenMapButton';
import ReviewCard from '../../../components/ReviewCard';
import SimpleNumberField from '../../../components/SimpleNumberField';
import StatusIcon from '../../../components/StatusIcon';
import TranslationsDrawer from '../../../components/TranslationsDrawer';
import TranslationsIcon from '../../../components/TranslationsIcon';

import AutocompleteOption from '../../../models/AutocompleteOption';
import Location from '../../../models/Location';
import LocationFormDataErrors from '../../../models/LocationFormDataErrors';
import { Translations } from '../../../models/Translations';

import { ApiCitiesSearch, ApiCountriesSearch, ApiLocations, ApiRefsTimezones } from '../../../constants/endpoints';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { LocaleEn } from '../../../constants/utils';
import { LocationInit } from '../../../constants/LocationInit';
import { LocationPointsOfSale } from '../../../constants/LocationPointsOfSale';
import { LocationTypes } from '../../../constants/LocationTypes';
import { UrlLocations } from '../../../constants/urls';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { collect_address, ucfirst } from '../../../utils/utils';
import { useValidateField } from '../../../services/useValidateField';
import alpha_num_validate from '../../../utils/validators/alpha_num';
import integer_validate from '../../../utils/validators/integer';
import numeric_validate from '../../../utils/validators/numeric';
import required_validate from '../../../utils/validators/required';
import size_validate from '../../../utils/validators/size';
import size_max_validate from '../../../utils/validators/size_max';
import url_validate from '../../../utils/validators/url';

export default function LocationsEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [drawerName, setDrawerName] = useState(false);
  const [drawerAddress, setDrawerAddress] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<Location>(LocationInit);
  const [loadedFormData, setLoadedFormData] = useState<Location>(LocationInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [validateField, , formErrors] = useValidateField(formData, {} as LocationFormDataErrors);
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const axiosHelper = useAxios();

  const List = styled('ul')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    minHeight: '35px',
    margin: 0,
  }));

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.7),
  }));

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const updateLoadedFormData = useCallback(
    (data: Location) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name || data.identifier);
    },
    [setTitle]
  );

  const onTypeChange = (event: SelectChangeEvent) => {
    setFormData((prevState) => ({ ...prevState, type: event.target.value as string }));
  };

  const onCountryChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      ...(newValue
        ? {
            country: newValue.id,
            countryName: newValue.name,
          }
        : { country: '', countryName: '' }),
    }));

    onCityChangeValue(null);
  };

  const onCityChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      ...(newValue
        ? { city: newValue.id, cityName: newValue.name }
        : {
            city: '',
            cityName: '',
          }),
    }));
  };

  const onAliasesChangeValue = (newValue: string[]) => {
    setFormData((prevState) => ({ ...prevState, aliases: newValue }));
  };

  const pointsOfSaleAdd = (value: string) => {
    const pos = formData.pointsOfSale.slice();
    pos.push(value);
    pos.sort();
    setFormData((prevState) => ({ ...prevState, pointsOfSale: pos }));
  };

  const pointsOfSaleRemove = (value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      pointsOfSale: prevState.pointsOfSale.filter((v) => v !== value),
    }));
  };

  const onTimezoneChangeValue = (newValue: AutocompleteOption | null) => {
    setFormData((prevState) => ({
      ...prevState,
      ...(newValue ? { timezone: newValue.id, timezoneName: newValue.name } : { timezone: '', timezoneName: '' }),
    }));
  };

  function onMainChange(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;

    setFormData((prevState) => ({ ...prevState, main: checked }));
  }

  function getDataFromResponse(response: any): Location {
    return {
      id: (response.id as string) ?? '0',
      identifier: (response.identifier as string) ?? '',
      name: (response.name as string) ?? '',
      uic: (response.uic as string) ?? '',
      iata: (response.iata as string) ?? '',
      icao: (response.icao as string) ?? '',
      ccon: (response.ccon as string) ?? '',
      official: (response.official as string) ?? '',
      eponym: (response.eponym as string) ?? '',
      website: (response.weblinks.website as string) ?? '',
      indoorMap: (response.weblinks.indoorMap as string) ?? '',
      directions: (response.weblinks.directions as string) ?? '',
      onsiteServices: (response.weblinks.onsiteServices as string) ?? '',
      opened: (response.opened as string) ?? '',
      closed: (response.closed as string) ?? '',
      main: Boolean(response.main),
      type: (response.type as string) ?? '',
      aliases: (response.aliases as string[]) ?? [],
      country: (response.country as string) ?? '',
      countryName: (response.countryName as string) ?? '',
      countryCode: (response.countryCode as string) ?? '',
      city: (response.city as string) ?? '',
      cityName: (response.cityName as string) ?? '',
      timezone: (response.timezone as string) ?? '',
      timezoneName: (response.timezoneName as string) ?? '',
      street: (response.street as string) ?? '',
      streetNumber: (response.streetNumber as string) ?? '',
      postcode: (response.postcode as string) ?? '',
      addressAdditional: (response.addressAdditional as string) ?? '',
      latitude: response.coordinates.latitude as string,
      longitude: response.coordinates.longitude as string,
      altitude: (response.altitude as string) ?? '',
      created: response.created as string,
      updated: response.updated as string,
      createdBy: response.createdBy as string,
      updatedBy: response.updatedBy as string,
      pointsOfSale: (response.pointsOfSale as string[]) ?? [],
      status: (response.review?.status as string) ?? '',
      notes: (response.review?.notes as string) ?? '',
      statusUpdated: response.review?.statusUpdated as string,
      statusUpdatedBy: response.review?.statusUpdatedBy as string,
      translations: response?.translations || {},
    };
  }

  const handleTranslationsNameChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      name: data[LocaleEn] || '',
      translations: {
        ...prevState.translations,
        name: data,
      },
    }));
  };

  const handleTranslationsAddressChange = (data: Translations): void => {
    setFormData((prevState) => ({
      ...prevState,
      name: data[LocaleEn] || '',
      translations: {
        ...prevState.translations,
        addressAdditional: data,
      },
    }));
  };
  const handleToggleDrawerName = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerName(open);
  };

  const handleToggleDrawerAddress = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerAddress(open);
  };

  const validate = (): boolean => {
    let valid = true;
    valid =
      validateField('iata', (value: string) => size_validate(value, 3)) &&
      validateField('iata', alpha_num_validate) &&
      valid;

    valid =
      validateField('icao', (value: string) => size_validate(value, 4)) &&
      validateField('icao', alpha_num_validate) &&
      valid;

    valid =
      validateField('uic', (value: string) => size_max_validate(value, 10)) &&
      validateField('uic', alpha_num_validate) &&
      valid;

    valid = validateField('type', required_validate, false) && valid;

    valid =
      validateField('opened', integer_validate) &&
      validateField('opened', (value: string) => size_validate(value, 4)) &&
      valid;

    valid =
      validateField('closed', integer_validate) &&
      validateField('closed', (value: string) => size_validate(value, 4)) &&
      valid;

    valid = validateField('website', url_validate) && valid;
    valid = validateField('indoorMap', url_validate) && valid;
    valid = validateField('directions', url_validate) && valid;
    valid = validateField('onsiteServices', url_validate) && valid;

    valid = validateField('latitude', numeric_validate) && valid;
    valid = validateField('longitude', numeric_validate) && valid;
    valid = validateField('altitude', integer_validate) && valid;

    return valid;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let sendData = {
      ...formData,
      coordinates: { latitude: formData.latitude, longitude: formData.longitude },
      review: { status: formData.status, notes: formData.notes },
      weblinks: {
        website: formData.website,
        indoorMap: formData.indoorMap,
        directions: formData.directions,
        onsiteServices: formData.onsiteServices,
      },
    };

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiLocations + '/' + locationId, sendData);
    } else {
      request = axiosHelper.post(ApiLocations, sendData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.locations)[0]));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'Location created' });
          navigate(UrlLocations);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (locationId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiLocations + '/' + locationId)
        .then((response) => {
          updateLoadedFormData(
            getDataFromResponse((Object.values(response.data.data.locations)[0] as Location) ?? LocationInit)
          );
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add location');
    }
  }, [locationId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (locationId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [locationId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiLocations + '/' + locationId)
        .then(() => {
          setDataSendStatus({ open: true, success: true, message: 'Location deleted' });
          navigate(UrlLocations);
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" action={<StatusIcon status={loadedFormData.status ?? ''} />} />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.identifier} />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="iata"
                          id="iata"
                          label="IATA code"
                          type="text"
                          value={formData.iata}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 3 }}
                          error={!!formErrors.iata}
                          helperText={formErrors.iata}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="icao"
                          id="icao"
                          label="ICAO code"
                          type="text"
                          value={formData.icao}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 4 }}
                          error={!!formErrors.icao}
                          helperText={formErrors.icao}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          fullWidth
                          name="uic"
                          id="uic"
                          label="UIC code"
                          type="text"
                          value={formData.uic}
                          onChange={onChange}
                          onBlur={() => validate()}
                          inputProps={{ maxLength: 10 }}
                          error={!!formErrors.uic}
                          helperText={formErrors.uic}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <CodeCconField value={formData.ccon} />
                    </Grid>
                  </Grid>

                  <Divider />

                  <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      value={formData.type}
                      label="Type"
                      name="type"
                      onChange={onTypeChange}
                      onBlur={() => validate()}
                      error={!!formErrors.type}
                    >
                      {LocationTypes.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.type}>
                            {ucfirst(v)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!formErrors.type && <FormHelperText>{formErrors.type}</FormHelperText>}
                  </FormControl>

                  <FormControl margin="dense" fullWidth sx={{ marginLeft: 1, marginBottom: 1 }}>
                    <FormControlLabel
                      control={<Switch checked={formData.main} onChange={onMainChange} />}
                      label="Main"
                      sx={{ '& .MuiFormControlLabel-label': { position: 'relative' } }}
                    />
                  </FormControl>

                  <Divider />

                  <Box sx={{ marginTop: 2 }} />
                  <NameField
                    value={(formData.translations.name && formData.translations.name[LocaleEn]) || ''}
                    onClick={handleToggleDrawerName}
                  />

                  <TranslationsDrawer
                    name="name"
                    label="Name"
                    open={drawerName}
                    globalDesignation={true}
                    translations={formData.translations.name}
                    onChange={handleTranslationsNameChange}
                    onClose={setDrawerName}
                  />

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="official">Official</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="official"
                      id="official"
                      label="Official"
                      type="text"
                      value={formData.official}
                      onChange={onChange}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="eponym">Eponym</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="eponym"
                      id="eponym"
                      label="Eponym"
                      type="text"
                      value={formData.eponym}
                      onChange={onChange}
                    />
                  </FormControl>

                  <Autocomplete
                    fullWidth
                    multiple
                    id="aliases"
                    options={[]}
                    freeSolo
                    value={formData.aliases}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="transfer"
                          color="primary"
                          label={option}
                          deleteIcon={<ClearIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ marginBottom: 2 }}
                        variant="outlined"
                        label="Aliases"
                        margin="dense"
                      />
                    )}
                    onChange={(_e, value) => {
                      onAliasesChangeValue(value);
                    }}
                  />

                  <Divider />

                  <Grid container columns={2} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="opened"
                        id="opened"
                        label="Opened (year)"
                        type="text"
                        value={formData.opened}
                        onChange={onChange}
                        onlyInteger={true}
                        inputProps={{ maxLength: 4 }}
                        onBlur={() => validate()}
                        error={!!formErrors.opened}
                        helperText={formErrors.opened}
                      />
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="closed"
                        id="closed"
                        label="Closed (year)"
                        type="text"
                        value={formData.closed}
                        onChange={onChange}
                        onlyInteger={true}
                        inputProps={{ maxLength: 4 }}
                        sx={{ backgroundColor: formData.closed ? 'rgb(255 112 112 / 30%)' : 'white' }}
                        onBlur={() => validate()}
                        error={!!formErrors.closed}
                        helperText={formErrors.closed}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card variant="island">
                <CardHeader title="Weblinks" />
                <CardContent>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="website"
                      id="website"
                      label="Website"
                      type="text"
                      value={formData.website}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.website}
                      helperText={formErrors.website}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="indoorMap"
                      id="indoorMap"
                      label="Indoor map"
                      type="text"
                      value={formData.indoorMap}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.indoorMap}
                      helperText={formErrors.indoorMap}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="directions"
                      id="directions"
                      label="Directions"
                      type="text"
                      value={formData.directions}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.directions}
                      helperText={formErrors.directions}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <TextField
                      fullWidth
                      name="onsiteServices"
                      id="onsiteServices"
                      label="On-site services"
                      type="text"
                      value={formData.onsiteServices}
                      onChange={onChange}
                      onBlur={() => validate()}
                      error={!!formErrors.onsiteServices}
                      helperText={formErrors.onsiteServices}
                    />
                  </FormControl>
                </CardContent>
              </Card>

              <Card variant="island">
                <CardHeader title="Points of sale" />
                <CardContent>
                  <List
                    sx={{
                      border: '1px solid #C4C4C4',
                      borderRadius: '0.3rem',
                    }}
                  >
                    {formData.pointsOfSale.map((item: string) => {
                      return (
                        <ListItem key={item}>
                          <Chip
                            label={item}
                            color="primary"
                            variant="transfer"
                            deleteIcon={<ClearIcon />}
                            onDelete={() => pointsOfSaleRemove(item)}
                          />
                        </ListItem>
                      );
                    })}
                  </List>

                  <List sx={{ marginTop: 1 }}>
                    {LocationPointsOfSale.map((item: string) => {
                      return (
                        formData.pointsOfSale.indexOf(item) === -1 && (
                          <ListItem key={item}>
                            <Chip
                              sx={{ cursor: 'pointer' }}
                              clickable={false}
                              label={item}
                              color="secondary"
                              variant="transfer"
                              deleteIcon={<CheckIcon />}
                              onDelete={() => pointsOfSaleAdd(item)}
                              onClick={() => pointsOfSaleAdd(item)}
                            />
                          </ListItem>
                        )
                      );
                    })}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader
                  title="Geo"
                  action={
                    <OpenMapButton
                      latitude={formData.latitude}
                      longitude={formData.longitude}
                      address={collect_address(
                        formData.street,
                        formData.streetNumber,
                        formData.postcode,
                        formData.cityName,
                        formData.countryName
                      )}
                      zoom="17z"
                    />
                  }
                />
                <CardContent>
                  <AutocompleteLoading
                    urlPrefix={ApiCountriesSearch}
                    label="Country"
                    value={formData.country ?? ''}
                    valueName={formData.countryName ?? ''}
                    onChangeValue={onCountryChangeValue}
                    loadAll={true}
                  />

                  <AutocompleteLoading
                    urlPrefix={ApiCitiesSearch}
                    label="City"
                    value={formData.city ?? ''}
                    valueName={formData.cityName ?? ''}
                    disabled={!Boolean(formData.country)}
                    parentId={formData.country}
                    onChangeValue={onCityChangeValue}
                  />

                  <AutocompleteLoading
                    urlPrefix={ApiRefsTimezones}
                    label="Timezone"
                    value={formData.timezone ?? undefined}
                    valueName={formData.timezoneName ?? ''}
                    loadAll={true}
                    onChangeValue={onTimezoneChangeValue}
                    inputSx={{ marginBottom: 2 }}
                  />

                  <Divider />

                  <Grid container columns={4} spacing={1}>
                    <Grid item lg={3} xs={1}>
                      <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
                        <InputLabel htmlFor="street">Street</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="street"
                          id="street"
                          label="Street"
                          type="text"
                          value={formData.street}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item columns={1} lg={1} xs={1}>
                      <FormControl margin="dense" sx={{ marginTop: 2 }} fullWidth>
                        <InputLabel htmlFor="streetNumber">No</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="streetNumber"
                          id="streetNumber"
                          label="No"
                          type="text"
                          value={formData.streetNumber}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="postcode">Postcode</InputLabel>
                        <OutlinedInput
                          fullWidth
                          name="postcode"
                          id="postcode"
                          label="Postcode"
                          type="text"
                          value={formData.postcode}
                          onChange={onChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl margin="dense" sx={{ marginBottom: 2 }} fullWidth>
                    <InputLabel htmlFor="addressAdditional">Address additional</InputLabel>
                    <OutlinedInput
                      fullWidth
                      readOnly
                      multiline
                      rows={4}
                      name="addressAdditional"
                      id="addressAdditional"
                      label="Address additional"
                      type="text"
                      value={
                        (formData.translations.addressAdditional &&
                          formData.translations.addressAdditional[LocaleEn]) ||
                        ''
                      }
                      onClick={handleToggleDrawerAddress(true)}
                      endAdornment={
                        <InputAdornment position="end" sx={{ position: 'absolute', top: 20, right: 15 }}>
                          <IconButton edge="end">
                            <TranslationsIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        '& .MuiInputBase-input': {
                          color: 'black',
                        },
                      }}
                    />
                  </FormControl>

                  <TranslationsDrawer
                    name="addressAdditional"
                    label="Address additional"
                    rows={3}
                    open={drawerAddress}
                    translations={formData.translations.addressAdditional}
                    onChange={handleTranslationsAddressChange}
                    onClose={setDrawerAddress}
                  />

                  <Divider />

                  <Grid container columns={2} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="latitude"
                        id="latitude"
                        label="Latitude"
                        type="text"
                        value={formData.latitude}
                        onChange={onChange}
                        onBlur={() => validate()}
                        error={!!formErrors.latitude}
                        helperText={formErrors.latitude}
                      />
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="longitude"
                        id="longitude"
                        label="Longitude"
                        type="text"
                        value={formData.longitude}
                        onChange={onChange}
                        onBlur={() => validate()}
                        error={!!formErrors.longitude}
                        helperText={formErrors.longitude}
                      />
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <SimpleNumberField
                        fullWidth
                        margin="dense"
                        name="altitude"
                        id="altitude"
                        label="Altitude (m)"
                        type="text"
                        onlyInteger={true}
                        value={formData.altitude}
                        onChange={onChange}
                        onBlur={() => validate()}
                        error={!!formErrors.altitude}
                        helperText={formErrors.altitude}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <ReviewCard
                action={action}
                initialStatus={loadedFormData.status}
                statusUpdated={formData.statusUpdated}
                statusUpdatedBy={formData.statusUpdatedBy}
                created={formData.created}
                notes={formData.notes}
                status={formData.status}
                onChange={(data) => setFormData({ ...formData, notes: data.notes, status: data.status })}
              />

              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="location"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
