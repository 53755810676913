import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '../../imports';

import SecondaryMenuItem from '../../models/SecondaryMenu/SecondaryMenuItem';

import useSecondaryMenu from '../../services/useSecondaryMenu';

export default function SecondaryMenuItemMultiple({ item }: { item: SecondaryMenuItem }) {
  const { selectedItems, setSelectedItems } = useSecondaryMenu();

  function handleClick() {
    const newSelectedSecondaryMenuItems = [...selectedItems];
    const itemIndex = selectedItems.indexOf(item);

    if (itemIndex >= 0) {
      newSelectedSecondaryMenuItems.splice(itemIndex, 1);
    } else {
      newSelectedSecondaryMenuItems.push(item);
    }

    setSelectedItems(newSelectedSecondaryMenuItems);
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Checkbox checked={selectedItems.includes(item)} />
        </ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
