import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { updated_as_string } from '../utils/utils';

export default function GridFieldUpdated(): GridColDef {
  return {
    field: 'updated',
    headerName: 'Last updated',
    flex: 8,
    renderCell: (params: GridRenderCellParams) => updated_as_string(params.value),
  };
}
