import { format } from 'date-fns';

import City from '../models/City';

import { DateFormatApiFull } from './utils';

export const CityInit: City = {
  id: '0',
  identifier: '',
  name: '',
  iata: '',
  country: '',
  countryName: '',
  iso: '',
  unlo: '',
  ccon: '',
  nameWithoutDiacritics: '',
  aliases: [],
  callingCodes: [],
  licensePlates: [],
  capital: false,
  latitude: '',
  longitude: '',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
  status: 'To verify',
  notes: '',
  statusUpdated: format(new Date(), DateFormatApiFull),
  statusUpdatedBy: '',
  website: '',
  tourismOffice: '',
  translations: {},
};
