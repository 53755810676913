import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Card,
  CardHeader,
  CardContent,
} from '../../imports';

import ChangePassword from './components/ChangePassword';
import EmailTextField from '../../components/EmailTextField';
import LoadingPage from '../../components/LoadingPage';
import LoadingButton from '../../components/LoadingButton';

import { UserEdit } from '../../models/user/UserEdit';

import { ApiUsers } from '../../constants/endpoints';
import { DataSendStatusInit } from '../../constants/DataSendStatus/DataSendStatusInit';
import { Salutations } from '../../constants/Salutations';
import { UserRoleAdmin, UserRoleOperator, UserRoleUser } from '../../constants/utils';
import { UserEditInit } from '../../constants/UserEditInit';

import { useAuth } from '../../services/useAuth';
import useAxios from '../../services/useAxios';
import { useDataSendStatus } from '../../services/useDataSendStatus';

import email_validate from '../../utils/validators/email';

interface formError {
  first_name: boolean;
  last_name: boolean;
  role: boolean;
}

export default function Profile() {
  const [data, setData] = useState<UserEdit>(UserEditInit);
  const [formData, setFormData] = useState<UserEdit>(data);

  const [error, setError] = useState<formError>({ first_name: false, last_name: false, role: false });

  const [loading, setLoading] = useState<boolean>(true);
  const [sendingData, setSendingData] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const { user } = useAuth();
  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const axiosHelper = useAxios();

  const loadFormData = useCallback(() => {
    setLoading(true);

    axiosHelper
      .get(ApiUsers + '/' + user.id)
      .then((result) => {
        if (result.data.meta && result.data.meta.success) {
          const userData = Object.values(result.data.data.users)[0] as UserEdit;

          userData.role = userData.role ?? UserRoleUser;
          setFormData(userData);
          setData(userData);
        }
      })
      .catch(checkResponseError)
      .finally(() => setLoading(false));
  }, [user.id, axiosHelper, checkResponseError]);

  const validate = () => {
    const e: string = email_validate(formData.email);
    if (e) {
      throw new Error(e);
    }

    let hasError: boolean = false;
    type keyType = keyof typeof error;
    Object.keys(error).forEach((key) => {
      const value: string = formData[key as keyType] ?? '';
      handleValidate(key, value);
      hasError = hasError || !value.length;
    });

    if (hasError) {
      throw new Error('Some fields is required');
    }
  };

  const handleReset = () => {
    setFormData(data);
  };

  const handleBlur = (event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleValidate(event.target.name, event.target.value);
  };

  const handleChange = (event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleValidate(event.target.name, event.target.value);

    setFormData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const handleChangeEmail = (value: string): void => {
    setFormData((prevState) => ({ ...prevState, email: value }));
  };

  const handleValidate = (name: string, value: string): void => {
    setError((prevState) => ({ ...prevState, [name]: !value.length }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // reset before request
    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    try {
      validate();

      axiosHelper
        .put(ApiUsers + '/' + user.id, formData)
        .then(() => {
          setData(formData);
          setSendingData(false);
          setDataSendStatus({
            open: true,
            message: 'Profile settings updated successfully.',
            success: true,
          });
        })
        .catch(checkResponseError)
        .finally(() => setSendingData(false));
    } catch (e) {
      setSendingData(false);
      setDataSendStatus({
        open: true,
        message: 'Unable to update profile settings.',
        success: false,
      });
    }
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  return (
    <>
      <ChangePassword open={openChangePassword} closeCallback={handleCloseChangePassword} />
      <Container maxWidth="lg" component="form" onSubmit={handleSubmit}>
        {loading && <LoadingPage />}
        {!loading && (
          <>
            <Grid container columns={2} spacing={'15px'}>
              <Grid item lg={1} xs={2}>
                <Card variant="island">
                  <CardHeader title="Details" />
                  <CardContent>
                    <FormControl fullWidth={false} margin="dense">
                      <InputLabel id="salutation-select-label">Salutation</InputLabel>
                      <Select
                        id="salutation-select"
                        labelId="salutation-select-label"
                        name="salutation"
                        label="Salutation"
                        value={formData.salutation ?? ''}
                        onChange={handleChange}
                      >
                        {Salutations.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                      <InputLabel htmlFor="first_name">First name(s)</InputLabel>
                      <OutlinedInput
                        id="first_name"
                        name="first_name"
                        label="First name(s)"
                        error={error.first_name}
                        value={formData.first_name ?? ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                      <InputLabel htmlFor="last_name">Last name</InputLabel>
                      <OutlinedInput
                        id="last_name"
                        name="last_name"
                        label="Last name"
                        error={error.last_name}
                        value={formData.last_name ?? ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                      <EmailTextField
                        id="email"
                        name="email"
                        label="Email"
                        value={formData.email ?? ''}
                        onChange={handleChangeEmail}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="dense" sx={{ marginBottom: 2 }}>
                      <TextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={formData.phone ?? ''}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Divider />
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{ marginTop: 2 }}
                      onClick={() => setOpenChangePassword(true)}
                    >
                      Change password
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={1} xs={2}>
                <Card variant="island">
                  <CardHeader title="Permissions" />
                  <CardContent>
                    <FormControl fullWidth={true} margin="dense" disabled={true}>
                      <InputLabel id="role-select-label">Role</InputLabel>
                      <Select
                        id="role-select"
                        labelId="role-select-label"
                        name="role"
                        label="Role"
                        error={error.role}
                        value={formData.role ?? UserRoleUser}
                        readOnly={true}
                        onChange={handleChange}
                      >
                        <MenuItem value={UserRoleAdmin}>Admin</MenuItem>
                        <MenuItem value={UserRoleOperator}>Operator</MenuItem>
                        <MenuItem value={UserRoleUser}>User</MenuItem>
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Paper variant="action" sx={{ mt: 3, paddingBottom: 4 }}>
              <LoadingButton type="submit" text="Save" sendingData={sendingData} sx={{ backgroundColor: "#1976D2" }} />
              <Button color="primary" variant="outlined" onClick={handleReset}  sx={{ backgroundColor: '#ffffff', color: '#1976D2', border: 0 }}>
                Reset
              </Button>
            </Paper>
          </>
        )}
      </Container>
    </>
  );
}
