import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '../imports';

import TranslationsIcon from './TranslationsIcon';

import React from 'react';

interface NameFieldProps {
  value: string;
  onClick: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function NameField({ value, onClick }: NameFieldProps) {
  return (
    <FormControl margin="dense" fullWidth>
      <InputLabel htmlFor="name">Name</InputLabel>
      <OutlinedInput
        fullWidth
        readOnly
        name="name"
        id="name"
        label="Name"
        type="text"
        value={value}
        onClick={onClick(true)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">
              <TranslationsIcon />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          '& .MuiInputBase-input': {
            color:'rgb(0, 0, 0, 0.87)',
          },
        }}
      />
    </FormControl>
  );
}
