import axios, { AxiosResponse } from 'axios';

import useAbortSignal from './useAbortSignal';
import { useAuth } from './useAuth';
import { useCallback, useMemo } from 'react';

interface axiosHelper {
  get: (url: string) => Promise<AxiosResponse<any, any>>;
  post: (url: string, sendData: any, header?: any) => Promise<AxiosResponse<any, any>>;
  put: (url: string, sendData: any) => Promise<AxiosResponse<any, any>>;
  delete: (url: string) => Promise<AxiosResponse<any, any>>;
}

export default function useAxios(): axiosHelper {
  const abortSignal = useAbortSignal();
  const { user } = useAuth();

  const get = useCallback(
    (url: string) => {
      return axios.get(url, {
        headers: { Authorization: user.token },
        signal: abortSignal,
      });
    },
    [user.token, abortSignal]
  );

  const post = useCallback(
    (url: string, sendData: any, header: any = {}) => {
      return axios.post(url, sendData, {
        headers: { Authorization: user.token, ...header },
        signal: abortSignal,
      });
    },
    [user.token, abortSignal]
  );

  const put = useCallback(
    (url: string, sendData: any) => {
      return axios.put(url, sendData, {
        headers: { Authorization: user.token },
        signal: abortSignal,
      });
    },
    [user.token, abortSignal]
  );

  const deleteMethod = useCallback(
    (url: string) => {
      return axios.delete(url, {
        headers: { Authorization: user.token },
        signal: abortSignal,
      });
    },
    [user.token, abortSignal]
  );

  return useMemo(() => {
    return {
      get: get,
      post: post,
      put: put,
      delete: deleteMethod,
    } as axiosHelper;
  }, [get, post, put, deleteMethod]);
}
